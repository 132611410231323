import { useEffect, useRef, useState } from "react";
import PanelContext from "../../store/panel-context";
import classes from "./Panel.module.css";

const Panel = (props) => {
  const [isOverflowing, setIsoverflowing] = useState(false);
  const [height, setHeight] = useState(0);
  const panelRef = useRef();
  const idx = props.idx;
  const overflowDispatch = props.overflowDispatch;

  useEffect(() => {
    // console.log(panelRef);
    const hasOverflowingChildren =
      panelRef.current.offsetHeight < panelRef.current.scrollHeight;
    // console.log(hasOverflowingChildren);
    if (isOverflowing !== hasOverflowingChildren) {
      setIsoverflowing(hasOverflowingChildren);
    }
    setHeight(panelRef.current.offsetHeight);
  });

  useEffect(() => {
    overflowDispatch({
      idx: idx,
      overflow: isOverflowing,
    });
  }, [isOverflowing, idx, overflowDispatch]);

  const padClass = props.rightPad ? classes.rightPad : classes.noRightPad;
  // const highlightClass = props.highlight
  //   ? classes.highlight
  //   : classes.noHighlight;
  const highlightClass = classes.noHighlight;

  return (
    <PanelContext.Provider
      value={{
        height,
      }}
    >
      <div
        ref={panelRef}
        className={`${classes.panel} ${padClass} ${highlightClass}`}
        style={{ flex: `${props.flex}%` }}
      >
        {props.children}
      </div>
    </PanelContext.Provider>
  );
};

export default Panel;
