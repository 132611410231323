import classes from "./Section.module.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import InputField from "./InputField";
import RemoveButton from "./RemoveButton";
import AddButton from "./AddButton";
import ResumeContext from "../../store/resume-builder-context";
import SectionContext from "../../store/section-context";


const Section = (props) => {
  const [isFocus, setIsFocus] = useState(false);
  const context = useContext(ResumeContext);
  const resumeState = context.resumeState;
  const templateState = context.templateState;
  const [sectionToPanelOffset, setSectionToPanelOffset] = useState(0);
  const sectionRef = useRef();

  const focusHandler = () => {
    setIsFocus(true);
  };

  const blurHandler = () => {
    setIsFocus(false);
  };

  useEffect(() => {
    // console.log(props.title, sectionRef.current.offsetTop);
    setSectionToPanelOffset(sectionRef.current.offsetTop);
  });

  // const highlightClass = isEditing ? classes.highlight : classes.noHighlight;
  const underlineSectionHeader = templateState?.underlineSectionHeader ? true: false;

  return (
    <SectionContext.Provider
      value={{
        sectionToPanelOffset
      }}
    >
      <div
      className={`${classes.section}`}
      onMouseEnter={focusHandler}
      onMouseLeave={blurHandler}
      ref={sectionRef}
    >
      {false && <RemoveButton />}
      <div className={classes.titleContainer}>
        <InputField
          placeholder={props.title}
          capitalize="true"
          fontWeight={700}
          fontSize={templateState.fontSize.xlarge}
          color={context.templateState.primaryColor}
          underline={underlineSectionHeader}
          id="title"
          onChange={props.onTitleChange}
        >
          {props.title}
        </InputField>
      </div>
      {props.children}
      { isFocus && props.onAdd != null && <AddButton onAdd={props.onAdd} /> }
    </div>
    </SectionContext.Provider>
  );
};

export default Section;
