import classes from "./ResumeDisplay.module.css";

import Page from "./Page";
import ResumeContext from "../../store/resume-builder-context";

import { useEffect, useRef } from "react";


const ResumeDisplay = (props) => {
  console.log("ResumeDisplay");
  const containerRef = useRef();
  const templateState = props.templateState;
  const resumeState = props.resumeState;
  let resumeStateDispatch = props.resumeStateDispatch;

  // console.log(resumeState);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !props.noEdit && containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        // console.log("outside page click useEffect recompute");
        // resumeStateDispatch({
        //   type: "pageStateRecompute",
        // });
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [containerRef]);

  const noEditClass = props.noEdit ? classes.noEdit : '';
  const displayScale = props.scale? props.scale : 1;
  const pagesToDisplay = props.showcase ? 1 : resumeState.pageState.pageCount;
  if (props.showcase) {
    resumeStateDispatch = () => {};
  }

  return (
    <ResumeContext.Provider
      value={{
        resumeState,
        templateState,
        resumeStateDispatch,
      }}
    >
      <div className={`${classes.container} ${noEditClass}`} ref={containerRef} style={{transform: `scale(${displayScale})`}}>
        {Array.from(Array(pagesToDisplay).keys()).map(
          (pageIndex) => {
            return <Page key={pageIndex} idx={pageIndex}></Page>;
          }
        )}
      </div>
    </ResumeContext.Provider>
  );
};

export default ResumeDisplay;
