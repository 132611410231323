import classes from "./InputField.module.css";
import { useState, useContext, useRef } from "react";
import DOMPurify from 'dompurify';
import EditableSectionContext from "../../store/editable-section-context";
import ResumeContext from "../../store/resume-builder-context";
require('medium-editor/dist/css/medium-editor.css');
require('medium-editor/dist/css/themes/beagle.css');

// if (typeof document !== 'undefined') {
//   var MediumEditor = require('medium-editor');
// }

const getFontWeight = (weight) => {
  switch(weight) {
    case 300:
      return classes.fontWeight300;
    case 400:
      return classes.fontWeight400;
    case 500:
      return classes.fontWeight500;
    case 700:
      return classes.fontWeight700;
    default:
      return classes.fontWeight400;
  }
};

const getFontSize = (size) => {
  switch(size) {
    case 10:
      return classes.fontSize10;
    case 15:
      return classes.fontSize15;
    case 18:
      return classes.fontSize18;
    case 20:
      return classes.fontSize20;
    case 25:
      return classes.fontSize25;
    case 30:
      return classes.fontSize30;
    case 35:
      return classes.fontSize35;
    case 40:
      return classes.fontSize40;
    case 45:
      return classes.fontSize45;
    case 50:
      return classes.fontSize50;
    default:
      return classes.fontSize18;
  }
};

const InputField = (props) => {
  const context = useContext(EditableSectionContext);
  const resumeContext = useContext(ResumeContext);
  const templateState = resumeContext.templateState;
  const [value, setValue] = useState(props.children);
  const field = useRef();

  const changeHandler = (event) => {
    // console.log(field.current.innerHTML);
    // setValue(field.current.innerHTML);
  };

  const isEditing = context.isEditing;
  const visible = isEditing || !props.optional || props.children.length > 0;

  const modeClass = isEditing ? classes.editMode : classes.viewMode;
  const visibilityClass = visible ? classes.show : classes.hide;
  const caseClass = props.capitalize ? classes.capitalCase : classes.anyCase;
  const fontWeightClass = getFontWeight(props.fontWeight);
  const fontSizeClass = getFontSize(props.fontSize);
  const alignClass = props.right ? classes.alignRight : classes.alignLeft;

  const color = props.color != null ? props.color : "#000000";
  const fontFamily = `${templateState.font[0]}, ${templateState.font[1]}`;

  const style = {
    color: color, 
    textDecorationColor: color,
    fontSize: props.fontSize, 
    fontFamily:  fontFamily
  }

  if (props.underline) {
    style.borderBottom = `2px solid ${color}` ;
  }

  if (props.align) {
    style.textAlign = props.align;
  }

  const content = DOMPurify.sanitize(props.children);

  return (
    <div
      className={`inputfield_editable ${classes.inputField} ${modeClass} ${visibilityClass} ${caseClass} ${classes.fontFamilyRoboto} ${fontWeightClass} ${fontSizeClass} ${alignClass}`}
      contentEditable="true"
      style={style}
      placeholder={props.placeholder}
      onBlur={() => {
        // console.log(`onBlur ${content}`);
        // context.setViewMode();
        if(content !== field.current.innerHTML) {
          props.onChange(props.id, field.current.innerHTML);  
        }
      }}
      onFocus={context.setEditMode}
      ref={field}
      suppressContentEditableWarning={true}
      dangerouslySetInnerHTML={{__html: content}}
    >
    </div>
  );
};

export default InputField;
