import { useContext } from "react";
import ResumeEditorContext from "../../store/resume-editor-context";
import classes from "./LayoutEditor.module.css";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const LayoutEditor = (props) => {
  const context = useContext(ResumeEditorContext);
  let sectionsOnPage = [];
  for (let i = 0; i < context.templateState.panels.length; i++) {
    sectionsOnPage.push(...context.templateState.panels[i].items);
  }
  const sectionsNotOnPage = context.sections.filter(
    (section) => !sectionsOnPage.includes(section)
  );
  const containerWidth = context.templateState.panels.length * 182 + 25;

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    console.log(destination, source, draggableId);

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (
      destination.droppableId === "available" &&
      source.droppableId !== "available"
    ) {
      context.templateStateDispatch({
        type: "removeSection",
        payload: {
          source: source.droppableId,
          sourceIndex: source.index,
        },
      });
    } else if (
      source.droppableId === "available" &&
      destination.droppableId !== "available"
    ) {
      context.templateStateDispatch({
        type: "addSection",
        payload: {
          destination: destination.droppableId,
          destinationIndex: destination.index,
          section: draggableId,
        },
      });
    } else if (
      source.droppableId !== "available" &&
      destination.droppableId !== "available"
    ) {
      context.templateStateDispatch({
        type: "rearrangeSection",
        payload: {
          source: source.droppableId,
          sourceIndex: source.index,
          destination: destination.droppableId,
          destinationIndex: destination.index,
          section: draggableId,
        },
      });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        className={classes.container}
        style={{ width: `${containerWidth}px` }}
      >
        <div className={classes.title}>Layout</div>
        <div className={classes.layout}>
          {context.templateState.panels.map((panel, index) => {
            return (
              <Droppable
                droppableId={`${index}`}
                key={index}
                renderClone={(provided, snapshot, rubric) => (
                  <div
                    className={classes.section}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    {panel.items[rubric.source.index].toUpperCase()}
                  </div>
                )}
              >
                {(provided) => {
                  return (
                    <div
                      className={classes.panel}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {panel.items.map((item, itemIndex) => {
                        return (
                          <Draggable
                            draggableId={item}
                            index={itemIndex}
                            key={item}
                          >
                            {(provided) => {
                              return (
                                <div
                                  className={classes.section}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  {item.toUpperCase()}
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            );
          })}
        </div>
        <div className={classes.title}>Available Sections</div>
        <div>
          <Droppable
            droppableId={`available`}
            renderClone={(provided, snapshot, rubric) => (
              <div
                className={classes.section}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
              >
                {sectionsNotOnPage[rubric.source.index].toUpperCase()}
              </div>
            )}
          >
            {(provided) => {
              return (
                <div
                  className={classes.availableSectionsContainer}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {sectionsNotOnPage.map((item, itemIndex) => {
                    return (
                      <Draggable
                        draggableId={item}
                        index={itemIndex}
                        key={item}
                      >
                        {(provided) => {
                          return (
                            <div
                              className={classes.section}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              {item.toUpperCase()}
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};

export default LayoutEditor;
