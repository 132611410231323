import classes from "./MyResumes.module.css";
import * as api from "../../api/index.js";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../store/app-context";
import ResumeEditorContext from "../../store/resume-editor-context";
import * as resumeUtil from "./ResumeUtil";
import MyResumeDisplay from "./MyResumeDisplay";
import AddButton from "../primitives/AddButton";

const loadMyResumes = async (authtoken) => {
  const response = await api.loadMyResumes(authtoken);
  if (response.status === 200) {
    const resumes = [];
    response.data.forEach((resume) => {
      resumes.push(resumeUtil.processResume(resume));
    });
    return resumes;
  }
};

const MyResumes = (props) => {
  const [myresumes, setMyResumes] = useState([]);
  const editorContext = useContext(ResumeEditorContext);
  const appContext = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    const load = async () => {
      appContext.showProgress(true);
      try {
        const resumes = await loadMyResumes();
        appContext.showProgress(false);
        console.log(`myresumes: ${resumes}`);
        console.log(resumes);
        if (resumes) {
          setMyResumes(resumes);
        }
        appContext.showProgress(false);
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          appContext.showProgress(false, 'Please login again', 'failure');
          appContext.setUser(null);
        } else if (error.response.data.hasOwnProperty("message")) {
          appContext.showProgress(
            false,
            error.response.data.message,
            "failure"
          );
        } else {
          appContext.showProgress(false, "Failed to load resumes", "failure");
        }
      } finally {
      }
    };
    load();
  }, [setMyResumes]);

  const deleteHandler = (resumeId) => {
    const resumes = myresumes.filter((resume) => resume.id != resumeId);
    setMyResumes(resumes);
  };

  const createHandler = () => {
    editorContext.openDrawer(null);
    history.push("/editor/new");
    // editorContext.resumeStateDispatch({
    //   type: "reset",
    // });
    // editorContext.templateStateDispatch({
    //   type: "reset",
    // });
  };

  return (
    <div className={classes.container}>
      <div className={classes.addContainer}>
        <AddButton onAdd={createHandler} size="ultrabig" />
      </div>
      <div className={classes.myresumesContainer}>
        {myresumes.map((resume) => {
          return (
            <MyResumeDisplay
              key={resume.id}
              resume={resume}
              onDelete={deleteHandler}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MyResumes;
