import classes from "./ListInputField.module.css";

import { useContext } from "react";
import EditableSectionContext from "../../store/editable-section-context";
import AddButton from "./AddButton";

const ListInputField = (props) => {
  const context = useContext(EditableSectionContext);
  const isEditing = context.isEditing;
  return (
    <div>
      <ul>{props.children}</ul>
      <div className={classes.add}>{isEditing && <AddButton onAdd={props.onAdd} size="small"/>}</div>
    </div>
  );
};

export default ListInputField;
