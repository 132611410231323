import { useContext } from "react";
import { useHistory } from 'react-router-dom';
import AppContext from "../../store/app-context";
import ResumeEditorContext from "../../store/resume-editor-context";
import classes from "./ControlBar.module.css";
import * as api from "../../api/index.js";

const ControlBar = (props) => {
  const appContext = useContext(AppContext);
  const editorContext = useContext(ResumeEditorContext);
  const history = useHistory();

  const saveHandler = async () => {
    appContext.showProgress(true);
        try {
          const response = await api.saveResume(
            editorContext.resumeId,
            editorContext.resumeState.data,
            editorContext.templateState
          );
          if (response.status === 200) {
            window.gtag('event', 'resume_save',
              {
                
              }
            );
            console.log("Resume saved!");
            console.log(`Resume Id: ${response.data.resumeId}`);
            // editorContext.setResumeId(response.data.resumeId);
            appContext.showProgress(false);
            editorContext.resumeStateDispatch({
              type: "pageStateRecompute",
            });
            editorContext.resumeStateDispatch({
              type: "saved",
            });
            if (editorContext.resumeId != response.data.resumeId) {
              history.push(`/editor/${response.data.resumeId}`);
            }
          } else {
            appContext.showProgress(false, 'Failed to save resume', 'failure');
          }
        } catch(error) {
          console.log(error);
          if (error.response.status === 401) {
            appContext.showProgress(false, 'Please login again', 'failure');
            appContext.setUser(null);
          } else if (error.response.data.hasOwnProperty('message')) {
            appContext.showProgress(false, error.response.data.message, 'failure');
          } else {
            appContext.showProgress(false, 'Failed to save resume', 'failure');
          }
        } finally {
          
        }
  }

  const itemClickHandler = async (item) => {
    console.log(item);
    switch (item) {
      case "register": {
        appContext.openAuthWindow("register");
        return;
      }
      case "signin": {
        appContext.openAuthWindow("signin");
        return;
      }
      case "signout": {
        api.signout();
        appContext.setUser(null);
        history.push('/');
        return;
      }
      case "save": {
        await saveHandler();
        return;
      }
      case "download": {
        await saveHandler();
        appContext.showProgress(true);
        try {
          const response = await api.downloadResume(
            editorContext.resumeId
          );
          console.log(response)
          if (response.status === 200) {
            console.log("Resume download!");
            console.log(`Resume Id: ${response.data.resumeId}`);
            window.gtag('event', 'resume_download',
              {
                templateId: editorContext.templateState.name,
                font: editorContext.templateState.font[0],
                primaryColor: editorContext.templateState.primaryColor,
                pages: editorContext.resumeState.pageState.pageCount,
              }
            );
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const filename = `${appContext.getUser().name}'s Resume.pdf`
            link.download = filename;
            link.click();
            appContext.showProgress(false);
          } else {
            appContext.showProgress(false, 'Failed to download resume', 'failure');
          }
        } catch(error) {
          if (error.response.status === 401) {
            appContext.showProgress(false, 'Please login again', 'failure');
            appContext.setUser(null);
          } else if (error.response.data.hasOwnProperty('message')) {
            appContext.showProgress(false, error.response.data.message, 'failure');
          } else {
            appContext.showProgress(false, 'Failed to download resume', 'failure');
          }
        } finally {
          
        }
        return;
      }
      default: {
        return;
      }
    }
  };

  return (
    <div>
      {!appContext.loggedIn && (
        <div className={classes.controlBarContainer}>
          <div className={classes.controlSaveHint}>
            Sign in to Save and Download
          </div>
          <div
            className={classes.controlItem}
            onClick={() => itemClickHandler("register")}
          >
            Register
          </div>
          <div
            className={classes.controlItem}
            onClick={() => itemClickHandler("signin")}
          >
            Sign In
          </div>
        </div>
      )}
      {appContext.loggedIn && (
        <div className={classes.controlBarContainer}>
          <div
            className={classes.controlItem}
            onClick={() => itemClickHandler("signout")}
          >
            Sign Out
          </div>
          <div
            className={classes.controlItem}
            onClick={() => itemClickHandler("save")}
          >
            Save
          </div>
          <div
            className={classes.controlItem}
            onClick={() => itemClickHandler("download")}
          >
            Download
          </div>
        </div>
      )}
    </div>
  );
};

export default ControlBar;
