import classes from './RemoveButton.module.css';
import DeleteIcon from "@material-ui/icons/Delete";

const RemoveButton = (props) => {
  return (
    // <div className={`${classes.remove}`} onClick={props.onRemove}>REMOVE</div>
    <DeleteIcon
      className={`${classes.remove}`}
      style={{ fontSize: 15 }}
      onClick={props.onRemove}
    />
  );
};

export default RemoveButton;