import { useContext } from "react";
import ResumeEditorContext from "../../store/resume-editor-context";
import classes from "./ColourPicker.module.css";

const ColourPicker = (props) => {
  const context = useContext(ResumeEditorContext);
  return (
    <div className={classes.colourPickerContainer}>
      {props.colours.map((colour) => {
        return (
          <div
            key={colour}
            className={classes.colourItem}
            style={{ backgroundColor: colour }}
            onClick={() => {context.primaryColourSelectionHandler(colour)}}
          />
        );
      })}
    </div>
  );
};

export default ColourPicker;
