import NavBar from "../../../components/NavBar";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import joeyResume from "../../../assets/blogs/joey_tribbiani_resume.png";
import CreateResumeButton from "../../../components/CreateResumeButton";

const Styles = styled.div`
  .blogContainer {
    background-color: #FFFFFF;
    background-color: aliceblue;
    padding-top: 50px;
    padding-bottom: 100px;
    font-family: "Roboto", sans-serif;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
    display: flex;
    justify-content: center;
  }

  .blogWrapper {
    max-width: 1000px;
  }

  p {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 40px;
    line-height: 1.5em;
  }

  h1 {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .resumeImg {
    border: 2px solid black;
    margin-top: 30px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    margin: 20px;
    margin-top: 50px;
  }
`;

const JoeyTribbianiResume = (props) => {
  return (
    <Styles>
      <NavBar />
      <div className="blogContainer">
        <Container className="blogWrapper">
          <h1>Joey Tribbiani's Resume</h1>
          <p>Not that the popular character needs a resume, we wondered what it would look like if he had one! With our simple, elegant, what-you-see-is-what-you-get professional resume editor the task wasn't hard.</p>
          <Image className="resumeImg" src={joeyResume} fluid />
          <p>So did you like our favourite character's professional resume? Ready to create yours?</p>
          <div className="buttonContainer">
            <CreateResumeButton/>
          </div>
        </Container>
      </div>
    </Styles>
  );
};

export default JoeyTribbianiResume;
