import classes from "./Project.module.css";

import { useContext, useEffect, useState } from "react";
import EditableSection from "../primitives/EditableSection";
import InputField from "../primitives/InputField";
import ListInputField from "../primitives/ListInputField";
import ListInputFieldItem from "../primitives/ListInputFieldItem";

import ResumeContext from "../../store/resume-builder-context";

const Project = (props) => {
  const project = props.data;

  const context = useContext(ResumeContext);
  const resumeState = context.resumeState;
  const templateState = context.templateState;
  const [isSubSectionEditing, setSubSectionEditState] = useState(false);

  const removeHandler = () => {
    context.resumeStateDispatch({
      type: "removeProject",
      payload: project.id,
    });
  };

  const addPointHandler = () => {
    context.resumeStateDispatch({
      type: "addProjectPoint",
      payload: project.id,
    });
  };

  const changeHandler = (id, value) => {
    switch (id) {
      case "title":
        project.title = value;
        break;
      case "period":
        project.period = value;
        break;
      case "description":
        project.description = value;
        break;
      default:
        for (var i in project.points) {
          if (project.points[i].id === id) {
            project.points[i].text = value;
          }
        }
    }
    console.log(project);
    context.resumeStateDispatch({
      type: "updateProject",
      payload: project,
    });
  };

  useEffect(() => {
    let needCleaning = false;
    for (var i in project.points) {
      if (project.points[i].text === "") {
        needCleaning = true;
      }
    }
    if (!isSubSectionEditing && needCleaning) {
      context.resumeStateDispatch({
        type: "cleanProject",
        payload: project.id,
      });
    }
  }, [isSubSectionEditing]);

  return (
    <EditableSection
      isRemovable={true}
      highlight={true}
      onRemove={removeHandler}
      setSubSectionEditState={setSubSectionEditState}
      page={props.page}
      panel={props.panel}
      section={props.section}
      id={props.id}
      key={props.id}
    >
      <div className={classes.experience}>
        <InputField
          placeholder="Project Title"
          optional={false}
          capitalize={false}
          fontWeight={700}
          fontSize={templateState.fontSize.medium}
          id="title"
          onChange={changeHandler}
        >
          {project.title}
        </InputField>

        <InputField
          placeholder="period"
          optional={false}
          fontWeight={400}
          fontSize={templateState.fontSize.small}
          id="period"
          onChange={changeHandler}
        >
          {project.period}
        </InputField>

        <InputField
          placeholder="Description (optional)"
          optional={true}
          fontWeight={300}
          fontSize={templateState.fontSize.small}
          id="description"
          onChange={changeHandler}
        >
          {project.description}
        </InputField>

        <ListInputField onAdd={addPointHandler}>
          {project.points.map((point) => {
            return (
              <ListInputFieldItem key={point.id}>
                <InputField
                  id={point.id}
                  onChange={changeHandler}
                  fontSize={templateState.fontSize.normal}
                  placeholder="Achievements"
                >
                  {point.text}
                </InputField>
              </ListInputFieldItem>
            );
          })}
        </ListInputField>
      </div>
    </EditableSection>
  );
};

export default Project;
