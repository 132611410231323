import { v4 as uuidv4 } from "uuid";
import * as resumeUtil from './ResumeUtil';

export const templateReducer = (state, action) => {
  const updatedState = { ...state };
  switch (action.type) {
    case "reset": {
      return resumeUtil.initTemplateState();
    }
    case "initialize": {
      return action.payload;
    }
    case "updatePanelWidth": {
      for (let i = 0; i < state.panels.length; i++) {
        if (i === 0) {
          updatedState.panels[i].flex = action.payload;
        } else {
          updatedState.panels[i].flex = 100 - action.payload;
        }
      }
      return updatedState;
    }
    case "selectTemplate": {
      let template = resumeUtil.templates.filter(
        (template) => template.id === action.payload
      );
      if (template.length > 0) {
        template = template[0];
      }
      if (template.primaryColor == null) {
        template.primaryColor = state.primaryColor;
      }
      if (template.font == null) {
        template.font = state.font;
      }
      if (template.fontSize == null) {
        template.fontSize = state.fontSize;
      }
      return template;
    }
    case "rearrangeSection": {
      updatedState.panels[action.payload.source].items.splice(action.payload.sourceIndex, 1);
      updatedState.panels[action.payload.destination].items.splice(action.payload.destinationIndex, 0, action.payload.section);
      return updatedState;
    }
    case "removeSection": {
      updatedState.panels[action.payload.source].items.splice(action.payload.sourceIndex, 1);
      return updatedState;
    }
    case "addSection": {
      updatedState.panels[action.payload.destination].items.splice(action.payload.destinationIndex, 0, action.payload.section);
      return updatedState;
    }
    case "selectColour": {
      updatedState.primaryColor = action.payload;
      return updatedState;
    }
    case "selectFont": {
      updatedState.font = action.payload;
      return updatedState;
    }
    case "selectFontSize": {
      if (action.payload === 's') {
        const fontSize = {};
        for (const [key, value] of Object.entries(resumeUtil.fontSizeBase)) {
          fontSize[key] = value * 0.9;
        }
        updatedState.fontSize = fontSize;
      } else if (action.payload === 'm') {
        updatedState.fontSize = resumeUtil.fontSizeBase
      } else if (action.payload === 'l') {
        const fontSize = {};
        for (const [key, value] of Object.entries(resumeUtil.fontSizeBase)) {
          fontSize[key] = value * 1.1;
        }
        updatedState.fontSize = fontSize;
      }
      return updatedState;
    }
    default: {
    }
  }
};


export const resumeReducer = (state, action) => {
  console.log(action);
  const updatedState = { ...state };

  const refreshPage = (pageIndexFrom, updatedState) => {
    if (pageIndexFrom == null) {
      return;
    }
    for (let section of Object.keys(updatedState.data)) {
      if (updatedState.data[section].hasOwnProperty("items")) {
        for (let i = 0; i < updatedState.data[section].items.length; i++) {
          if (updatedState.data[section].items[i].page >= pageIndexFrom) {
            updatedState.data[section].items[i].page = pageIndexFrom;
          }
        }
      }
    }
    updatedState.pageState.pageCount = pageIndexFrom + 1;
  };

  switch (action.type) {
    case "reset": {
      return resumeUtil.initResumeState();
    }
    case "initialize": {
      action.payload.unsavedChanges = false;
      return action.payload;
    }
    case "saved": {
      updatedState.unsavedChanges = false;
      return updatedState;
    }
    case "updatePersonalDetails": {
      updatedState.data.personalDetails = action.payload;
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "addExperience": {
      const pageToAdd = updatedState.data.experiences.items.slice(-1)[0].page;
      const experience = resumeUtil.emptyExperience();
      experience.page = pageToAdd;
      updatedState.data.experiences.items.push(experience);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "removeExperience": {
      const id = action.payload;
      let experienceToRemove = updatedState.data.experiences.items.filter(
        (experience) => experience.id === id
      );
      let pageRefreshFrom = 0;
      if (experienceToRemove.length > 0) {
        experienceToRemove = experienceToRemove[0];
        pageRefreshFrom = experienceToRemove.page;
      }
      const experiences = updatedState.data.experiences.items.filter(
        (experience) => experience.id !== id
      );
      if (experiences.length === 0) {
        const emptyExperience = resumeUtil.emptyExperience();
        emptyExperience.page = pageRefreshFrom;
        experiences.push(emptyExperience)
      }
      updatedState.data.experiences.items = experiences;
      refreshPage(pageRefreshFrom, updatedState);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "addExperiencePoint": {
      for (const experience of updatedState.data.experiences.items) {
        if (experience.id === action.payload) {
          experience.points.push({
            id: uuidv4(),
            text: "",
          });
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateExperience": {
      for (var i in updatedState.data.experiences.items) {
        if (updatedState.data.experiences.items[i].id === action.payload.id) {
          updatedState.data.experiences.items[i] = action.payload;
          updatedState.data.experiences.items[i].points =
            updatedState.data.experiences.items[i].points.filter(
              (point, j) => point.text.length > 0
            );
          if (updatedState.data.experiences.items[i].points.length === 0) {
            updatedState.data.experiences.items[i].points.push({
              id: uuidv4(),
              text: "",
            });
          }
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "cleanExperience": {
      for (var i in updatedState.data.experiences.items) {
        if (updatedState.data.experiences.items[i].id === action.payload) {
          updatedState.data.experiences.items[i].points =
            updatedState.data.experiences.items[i].points.filter(
              (point, j) => point.text.length > 0
            );
          if (updatedState.data.experiences.items.length === 0) {
            updatedState.data.experiences.points.push({
              id: uuidv4(),
              text: "",
            });
          }
          const pageRefreshFrom = updatedState.data.experiences.items[i].page;
          refreshPage(pageRefreshFrom, updatedState);
        }
      }
      return updatedState;
    }
    case "updateExperienceTitle": {
      updatedState.data.experiences.title = action.payload;
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "addEducation": {
      const pageToAdd = updatedState.data.educations.items.slice(-1)[0].page;
      const education = resumeUtil.emptyEducation();
      education.page = pageToAdd;
      updatedState.data.educations.items.push(education);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "removeEducation": {
      const id = action.payload;
      let educationToRemove = updatedState.data.educations.items.filter(
        (education) => education.id === id
      );
      let pageRefreshFrom = 0;
      if (educationToRemove.length > 0) {
        educationToRemove = educationToRemove[0];
        pageRefreshFrom = educationToRemove.page;
      }
      const educations = updatedState.data.educations.items.filter(
        (education) => education.id !== id
      );
      if (educations.length === 0) {
        const emptyEducation = resumeUtil.emptyEducation();
        emptyEducation.page = pageRefreshFrom;
        educations.push(emptyEducation)
      }
      updatedState.data.educations.items = educations;
      refreshPage(pageRefreshFrom, updatedState);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "addEducationPoint": {
      for (const education of updatedState.data.educations.items) {
        if (education.id === action.payload) {
          education.points.push({
            id: uuidv4(),
            text: "",
          });
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateEducation": {
      for (var i in updatedState.data.educations.items) {
        if (updatedState.data.educations.items[i].id === action.payload.id) {
          updatedState.data.educations.items[i] = action.payload;
          updatedState.data.educations.items[i].points =
            updatedState.data.educations.items[i].points.filter(
              (point, j) => point.text.length > 0
            );
          if (updatedState.data.educations.items[i].points.length === 0) {
            updatedState.data.educations.items[i].points.push({
              id: uuidv4(),
              text: "",
            });
          }
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "cleanEducation": {
      for (var i in updatedState.data.educations.items) {
        if (updatedState.data.educations.items[i].id === action.payload) {
          updatedState.data.educations.items[i].points =
            updatedState.data.educations.items[i].points.filter(
              (point, j) => point.text.length > 0
            );
          if (updatedState.data.educations.items.length === 0) {
            updatedState.data.educations.points.push({
              id: uuidv4(),
              text: "",
            });
            const pageRefreshFrom = updatedState.data.educations.items[i].page;
            refreshPage(pageRefreshFrom, updatedState);
          }
        }
      }
      return updatedState;
    }
    case "updateEducationTitle": {
      updatedState.data.educations.title = action.payload;
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "addProject": {
      const pageToAdd = updatedState.data.projects.items.slice(-1)[0].page;
      const project = resumeUtil.emptyProject();
      project.page = pageToAdd;
      updatedState.data.projects.items.push(project);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "removeProject": {
      const id = action.payload;
      let projectToRemove = updatedState.data.educations.items.filter(
        (project) => project.id === id
      );
      let pageRefreshFrom = 0;
      if (projectToRemove.length > 0) {
        projectToRemove = projectToRemove[0];
        pageRefreshFrom = projectToRemove.page;
      }
      const projects = updatedState.data.projects.items.filter(
        (project) => project.id !== id
      );
      if (projects.length === 0) {
        const emptyProject = resumeUtil.emptyProject();
        emptyProject.page = pageRefreshFrom;
        projects.push(emptyProject)
      }
      updatedState.data.projects.items = projects;
      refreshPage(pageRefreshFrom, updatedState);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "addProjectPoint": {
      for (const project of updatedState.data.projects.items) {
        if (project.id === action.payload) {
          project.points.push({
            id: uuidv4(),
            text: "",
          });
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateProject": {
      for (var i in updatedState.data.projects.items) {
        if (updatedState.data.projects.items[i].id === action.payload.id) {
          updatedState.data.projects.items[i] = action.payload;
          updatedState.data.projects.items[i].points =
            updatedState.data.projects.items[i].points.filter(
              (point, j) => point.text.length > 0
            );
          if (updatedState.data.projects.items[i].points.length === 0) {
            updatedState.data.projects.items[i].points.push({
              id: uuidv4(),
              text: "",
            });
          }
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "cleanProject": {
      for (var i in updatedState.data.projects.items) {
        if (updatedState.data.projects.items[i].id === action.payload) {
          updatedState.data.projects.items[i].points =
            updatedState.data.projects.items[i].points.filter(
              (point, j) => point.text.length > 0
            );
          if (updatedState.data.projects.items.length === 0) {
            updatedState.data.projects.points.push({
              id: uuidv4(),
              text: "",
            });
          }
          const pageRefreshFrom = updatedState.data.projects.items[i].page;
          refreshPage(pageRefreshFrom, updatedState);
        }
      }
      return updatedState;
    }
    case "updateProjectsTitle": {
      updatedState.data.projects.title = action.payload;
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "addSkill": {
      const pageToAdd = updatedState.data.skills.items.slice(-1)[0].page;
      const skill = resumeUtil.emptySkill();
      skill.page = pageToAdd;
      updatedState.data.skills.items.push(skill);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "removeSkill": {
      const id = action.payload;
      let skillToRemove = updatedState.data.skills.items.filter(
        (skill) => skill.id === id
      );
      let pageRefreshFrom = 0;
      if (skillToRemove.length > 0) {
        skillToRemove = skillToRemove[0];
        pageRefreshFrom = skillToRemove.page;
      }
      const skills = updatedState.data.skills.items.filter(
        (skill) => skill.id !== id
      );
      if (skills.length === 0) {
        const emptySkill = resumeUtil.emptySkill();
        emptySkill.page = pageRefreshFrom;
        skills.push(emptySkill)
      }
      updatedState.data.skills.items = skills;
      refreshPage(pageRefreshFrom, updatedState);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateSkill": {
      for (var i in updatedState.data.skills.items) {
        if (updatedState.data.skills.items[i].id === action.payload.id) {
          updatedState.data.skills.items[i] = action.payload;
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateSkillsTitle": {
      updatedState.data.skills.title = action.payload;
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "addInterest": {
      const pageToAdd = updatedState.data.interests.items.slice(-1)[0].page;
      const interest = resumeUtil.emptyInterest();
      interest.page = pageToAdd;
      updatedState.data.interests.items.push(interest);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "removeInterest": {
      const id = action.payload;
      let interestToRemove = updatedState.data.interests.items.filter(
        (interest) => interest.id === id
      );
      let pageRefreshFrom = 0;
      if (interestToRemove.length > 0) {
        interestToRemove = interestToRemove[0];
        pageRefreshFrom = interestToRemove.page;
      }
      const interests = updatedState.data.interests.items.filter(
        (interest) => interest.id !== id
      );
      if (interests.length === 0) {
        const emptyInterest = resumeUtil.emptyInterest();
        emptyInterest.page = pageRefreshFrom;
        interests.push(emptyInterest);
      }
      updatedState.data.interests.items = interests;
      refreshPage(pageRefreshFrom, updatedState);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateInterest": {
      for (var i in updatedState.data.interests.items) {
        if (updatedState.data.interests.items[i].id === action.payload.id) {
          updatedState.data.interests.items[i] = action.payload;
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateInterestsTitle": {
      updatedState.data.interests.title = action.payload;
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "addLanguage": {
      const pageToAdd = updatedState.data.languages.items.slice(-1)[0].page;
      const language = resumeUtil.emptyLanguage();
      language.page = pageToAdd;
      updatedState.data.languages.items.push(language);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "removeLanguages": {
      const id = action.payload;
      let languageToRemove = updatedState.data.languages.items.filter(
        (language) => language.id === id
      );
      let pageRefreshFrom = 0;
      if (languageToRemove.length > 0) {
        languageToRemove = languageToRemove[0];
        pageRefreshFrom = languageToRemove.page;
      }
      const languages = updatedState.data.languages.items.filter(
        (language) => language.id !== id
      );
      if (languages.length === 0) {
        const emptyLanguage = resumeUtil.emptyLanguage();
        emptyLanguage.page = pageRefreshFrom;
        languages.push(emptyLanguage);
      }
      updatedState.data.languages.items = languages;
      refreshPage(pageRefreshFrom, updatedState);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateLanguage": {
      for (var i in updatedState.data.languages.items) {
        if (updatedState.data.languages.items[i].id === action.payload.id) {
          updatedState.data.languages.items[i] = action.payload;
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateLanguagesTitle": {
      updatedState.data.languages.title = action.payload;
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "addAchievement": {
      const pageToAdd = updatedState.data.achievements.items.slice(-1)[0].page;
      const achievement = resumeUtil.emptyAchievement();
      achievement.page = pageToAdd;
      updatedState.data.achievements.items.push(achievement);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "removeAchievement": {
      const id = action.payload;
      let achievementToRemove = updatedState.data.achievements.items.filter(
        (achievement) => achievement.id === id
      );
      let pageRefreshFrom = 0;
      if (achievementToRemove.length > 0) {
        achievementToRemove = achievementToRemove[0];
        pageRefreshFrom = achievementToRemove.page;
      }
      const achievements = updatedState.data.achievements.items.filter(
        (achievement) => achievement.id !== id
      );
      if (achievements.length === 0) {
        const emptyAchievement = resumeUtil.emptyAchievement();
        emptyAchievement.page = pageRefreshFrom;
        achievements.push(emptyAchievement)
      }
      updatedState.data.achievements.items = achievements;
      refreshPage(pageRefreshFrom, updatedState);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateAchievement": {
      for (var i in updatedState.data.achievements.items) {
        if (updatedState.data.achievements.items[i].id === action.payload.id) {
          updatedState.data.achievements.items[i] = action.payload;
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateAchievementsTitle": {
      updatedState.data.achievements.title = action.payload;
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "addCertificate": {
      const pageToAdd = updatedState.data.certificates.items.slice(-1)[0].page;
      const certificate = resumeUtil.emptyCertificate();
      certificate.page = pageToAdd;
      updatedState.data.certificates.items.push(certificate);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "removeCertificate": {
      const id = action.payload;
      let certificateToRemove = updatedState.data.certificates.items.filter(
        (certificate) => certificate.id === id
      );
      let pageRefreshFrom = 0;
      if (certificateToRemove.length > 0) {
        certificateToRemove = certificateToRemove[0];
        pageRefreshFrom = certificateToRemove.page;
      }
      const certificates = updatedState.data.certificates.items.filter(
        (certificate) => certificate.id !== id
      );
      if (certificates.length === 0) {
        const emptyCertificate = resumeUtil.emptyCertificate();
        emptyCertificate.page = pageRefreshFrom;
        certificates.push(emptyCertificate)
      }
      updatedState.data.certificates.items = certificates;
      refreshPage(pageRefreshFrom, updatedState);
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateCertificate": {
      for (var i in updatedState.data.certificates.items) {
        if (updatedState.data.certificates.items[i].id === action.payload.id) {
          updatedState.data.certificates.items[i] = action.payload;
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateCertificatesTitle": {
      updatedState.data.certificates.title = action.payload;
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateSummary": {
      for (var i in updatedState.data.summary.items) {
        if (updatedState.data.summary.items[i].id === action.payload.id) {
          updatedState.data.summary.items[i] = action.payload;
        }
      }
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "updateSummaryTitle": {
      updatedState.data.summary.title = action.payload;
      updatedState.unsavedChanges = true;
      return updatedState;
    }
    case "pageStateInvisible": {
      const page = action.page;
      const section = action.section;
      const panel = action.panel;
      const subsecid = action.id;

      const idIndex = updatedState.data[section].items
        .map((item) => item.id)
        .indexOf(subsecid);
      if (idIndex > -1) {
        for (let i = 0; i < updatedState.data[section].items.length; i++) {
          if (i >= idIndex) {
            updatedState.data[section].items[i].page = page + 1;
          }
        }
      }
      let pageCount = 1;
      for (let section of Object.keys(updatedState.data)) {
        if (updatedState.data[section].hasOwnProperty("items")) {
          for (let i = 0; i < updatedState.data[section].items.length; i++) {
            const page = updatedState.data[section].items[i].page;
            if (page + 1 > pageCount) {
              pageCount = page + 1;
            }
          }
        }
      }
      updatedState.pageState.pageCount = pageCount;
      return updatedState;
    }
    case "pageStateRecompute": {
      refreshPage(0, updatedState);
      return updatedState;
    }
    default: {}
  }
};
