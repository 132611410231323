import { v4 as uuidv4 } from "uuid";

const resumeContentJay = {
  personalDetails: {
    name: "Jayasooryan K V",
    title: "Data Scientist | Kaggle Competition Expert",
    pitch: "Aspiring to build intelligent services",
    email: "jayasooryanpnr@gmail.com",
    phone: "+91-8281980288",
    links: [
      {
        id: uuidv4(),
        text: "www.linkedin.com/in/jayasoo",
      },
      {
        id: uuidv4(),
        text: "www.kaggle.com/jayasoo",
      },
    ],
    page: 0
  },
  experiences: {
    title: "Experience",
    items: [
      {
        id: uuidv4(),
        position: "Application Engineer",
        company: "Instabase",
        description: "",
        period: "June 2020 - Present",
        points: [
          {
            id: uuidv4(),
            text: "Created an application that can identify important information such as a group of signatories and their authorization limits from board resolution documents",
          },
          {
            id: uuidv4(),
            text: "Created many data extraction applications for various clients",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        position: "Software Engineer 1",
        company: "Samsung R&D Institute, Bangalore",
        description: "",
        period: "July 2017 - May 2020",
        points: [
          {
            id: uuidv4(),
            text: "Data scientist role for Samsung's payment platform: Samsung Pay",
          },
          {
            id: uuidv4(),
            text: "Created churn prediction models for Bill payment service with 85% accuracy and 0.9 F1 Score",
          },
          {
            id: uuidv4(),
            text: "Geographical clustering of failure points of card payment service to produce heat maps",
          },
          {
            id: uuidv4(),
            text: "Weekly data science report with analysis of various payment services such as UPI, bill payment, gift cards, wallets",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        position: "Software Engineer 2",
        company: "Samsung R&D Institute, Bangalore",
        description: "",
        period: "July 2017 - May 2020",
        points: [
          {
            id: uuidv4(),
            text: "Data scientist role for Samsung's payment platform: Samsung Pay",
          },
          {
            id: uuidv4(),
            text: "Created churn prediction models for Bill payment service with 85% accuracy and 0.9 F1 Score",
          },
          {
            id: uuidv4(),
            text: "Geographical clustering of failure points of card payment service to produce heat maps",
          },
          {
            id: uuidv4(),
            text: "Weekly data science report with analysis of various payment services such as UPI, bill payment, gift cards, wallets",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        position: "Software Engineer 3",
        company: "Samsung R&D Institute, Bangalore",
        description: "",
        period: "July 2017 - May 2020",
        points: [
          {
            id: uuidv4(),
            text: "Data scientist role for Samsung's payment platform: Samsung Pay",
          },
          {
            id: uuidv4(),
            text: "Created churn prediction models for Bill payment service with 85% accuracy and 0.9 F1 Score",
          },
          {
            id: uuidv4(),
            text: "Geographical clustering of failure points of card payment service to produce heat maps",
          },
          {
            id: uuidv4(),
            text: "Weekly data science report with analysis of various payment services such as UPI, bill payment, gift cards, wallets",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        position: "Software Engineer 4",
        company: "Samsung R&D Institute, Bangalore",
        description: "",
        period: "July 2017 - May 2020",
        points: [
          {
            id: uuidv4(),
            text: "Data scientist role for Samsung's payment platform: Samsung Pay",
          },
          {
            id: uuidv4(),
            text: "Created churn prediction models for Bill payment service with 85% accuracy and 0.9 F1 Score",
          },
          {
            id: uuidv4(),
            text: "Geographical clustering of failure points of card payment service to produce heat maps",
          },
          {
            id: uuidv4(),
            text: "Weekly data science report with analysis of various payment services such as UPI, bill payment, gift cards, wallets",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        position: "Software Engineer 5",
        company: "Samsung R&D Institute, Bangalore",
        description: "",
        period: "July 2017 - May 2020",
        points: [
          {
            id: uuidv4(),
            text: "Data scientist role for Samsung's payment platform: Samsung Pay",
          },
          {
            id: uuidv4(),
            text: "Created churn prediction models for Bill payment service with 85% accuracy and 0.9 F1 Score",
          },
          {
            id: uuidv4(),
            text: "Geographical clustering of failure points of card payment service to produce heat maps",
          },
          {
            id: uuidv4(),
            text: "Weekly data science report with analysis of various payment services such as UPI, bill payment, gift cards, wallets",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        position: "Software Engineer 6",
        company: "Samsung R&D Institute, Bangalore",
        description: "",
        period: "July 2017 - May 2020",
        points: [
          {
            id: uuidv4(),
            text: "Data scientist role for Samsung's payment platform: Samsung Pay",
          },
          {
            id: uuidv4(),
            text: "Created churn prediction models for Bill payment service with 85% accuracy and 0.9 F1 Score",
          },
          {
            id: uuidv4(),
            text: "Geographical clustering of failure points of card payment service to produce heat maps",
          },
          {
            id: uuidv4(),
            text: "Weekly data science report with analysis of various payment services such as UPI, bill payment, gift cards, wallets",
          },
        ],
        page: 0,
      },
    ],
  },
  educations: {
    title: "education",
    items: [
      {
        id: uuidv4(),
        course: "Masters in Computer Science",
        institute: "University of Texas, Austin",
        description: "",
        period: "Aug 2020 - Present",
        points: [
          {
            id: uuidv4(),
            text: "Took courses such as <b>Linear Algebra</b> and <b>Deep Learning</b>",
          },
          {
            id: uuidv4(),
            text: "Current CGPA is 3.8/4.0",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        course: "B.Tech in Computer Science",
        institute: "National Institute of Technology, Karnataka",
        description: "",
        period: "July 2013 - May 2017",
        points: [
          {
            id: uuidv4(),
            text: "Absolutely spent hours in hostel room sleeping",
          },
          {
            id: uuidv4(),
            text: "Ordered food from night canteens",
          },
          {
            id: uuidv4(),
            text: "Co-founded Opin in the the final year with best friends",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        course: "Masters in Computer Science",
        institute: "University of Texas, Austin",
        description: "",
        period: "Aug 2020 - Present",
        points: [
          {
            id: uuidv4(),
            text: "Took courses such as <b>Linear Algebra</b> and <b>Deep Learning</b>",
          },
          {
            id: uuidv4(),
            text: "Current CGPA is 3.8/4.0",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        course: "B.Tech in Computer Science",
        institute: "National Institute of Technology, Karnataka",
        description: "",
        period: "July 2013 - May 2017",
        points: [
          {
            id: uuidv4(),
            text: "Absolutely spent hours in hostel room sleeping",
          },
          {
            id: uuidv4(),
            text: "Ordered food from night canteens",
          },
          {
            id: uuidv4(),
            text: "Co-founded Opin in the the final year with best friends",
          },
        ],
        page: 0,
      },
    ],
  },
  projects: {
    title: "Projects",
    items: [
      {
        id: uuidv4(),
        title: "Kaggle Data Science Bowl 2019 - Silver Medal",
        period: "Jan 2020 - May 2017",
        description: "",
        points: [
          {
            id: uuidv4(),
            text: "Fifth annual Data Science Bowl, presented by Booz Allen Hamilton and Kaggle",
          },
          {
            id: uuidv4(),
            text: "Predict accuracy group of each child taking an assessment given his/her historical gamjjbeplay data",
          },
          {
            id: uuidv4(),
            text: "Created a stable ensemble of LightGBM and XGBoost models",
          },
          {
            id: uuidv4(),
            text: "Placed 75th out of 3497 participants",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        title: "Kaggle APTOS 2019 Blindness Detection - Bronze Medal",
        period: "Jan 2021 - May 2021",
        description: "",
        points: [
          {
            id: uuidv4(),
            text: "Computer vision challenge to identify the stage of Diabetic Retinopathy from images of eye scans",
          },
          {
            id: uuidv4(),
            text: "Created an EffiecientNet-b3 model pre-trained on previous year's competition data and fine-tuned on this year's competition data",
          },
          {
            id: uuidv4(),
            text: "Keras library for training neural networks and imgaug library for image augmentation",
          },
        ],
        page: 0,
      },
    ],
  },
  skills: {
    title: "Skills",
    items: [
      {
        id: uuidv4(),
        name: "Predictive modeling",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Computer vision",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Deep learning",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Machine learning",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Python",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Tensorflow",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Keras",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Android",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "SQL",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Java",
        page: 0,
      },
    ],
  },
  summary: {
    title: "summary",
    items: [
      {
        id: uuidv4(),
        description: "Software engineer with a passion for everything technology-related. By virtue of working in a product startup that is defining the next phase of computing starting with enterprises, With over 4 years of experience as a software developer with an early age startup like Instabase to a large organization such as Samsung, I have gained subject matter expertise in machine learning, deep learning, and computer vision. A self-starter, learning everything he can from work as well as at home on his self-assembled deep learning machine.",
        page: 0
      }
    ]
  },
  achievements: {
    title: "achievements",
    items: [
        {
            id: uuidv4(),
            title: "Samsung citizen Award 2018",
            description: "Awarded the prestigious Samsung Citizen Award for going above and beyond for building Samsung Pay Gift Card service",
            page: 0,
        },
        {
            id: uuidv4(),
            title: "Samsung Professional Coding Certificate",
            description: "Received professional level coding certificate for passing the much-coveted coding competition conducted by Samsung for its employees",
            page: 0,    
        }
    ]
  },
  certificates: {
    title: "certificates",
    items: [
        {
            id: uuidv4(),
            title: "Machine Learning by Stanford University on Coursera",
            description: "An amazing course by the much revered Andrew NG",
            period: "February 2019",
            page: 0,
        }
    ]
  },
  interests: {
    title: "interests",
    items: [
        {
            id: uuidv4(),
            name: "Photography",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Cooking",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Trekking",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Reading",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Coding",
            page: 0,
        }
    ]
  },
  languages: {
    title: "languages",
    items: [
        {
            id: uuidv4(),
            name: "English",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Malayalam",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Hindi",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Tamil",
            page: 0,
        }
    ]
}
};

const resumeContent = {
  personalDetails: {
    name: "John Doe",
    title: "Data Scientist | Kaggle Competition Master",
    pitch: "Aspiring to build intelligent services",
    email: "johndoe@gmail.com",
    phone: "+91-828198032",
    links: [
      {
        id: uuidv4(),
        text: "www.linkedin.com/john.doe",
      },
      {
        id: uuidv4(),
        text: "www.kaggle.com/john.doe",
      },
    ],
    page: 0
  },
  experiences: {
    title: "Experience",
    items: [
      {
        id: uuidv4(),
        position: "Senior Software Engineer",
        company: "Samsung Research Institue, Bengaluru",
        description: "",
        period: "June 2020 - Present",
        points: [
          {
            id: uuidv4(),
            text: "Responsible for creation of machine learning models for churn prediction of various Samsung services",
          },
          {
            id: uuidv4(),
            text: "Created end to end machine learning pipelines to update centralized user profiles with recommendations",
          },
          {
            id: uuidv4(),
            text: "Increased user click through rate of Ad services by 40%",
          },
          {
            id: uuidv4(),
            text: "Successfully managed a team of 4 people",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        position: "Software Engineer",
        company: "Samsung Research Institute, Bengaluru",
        description: "",
        period: "July 2017 - May 2020",
        points: [
          {
            id: uuidv4(),
            text: "Worked on Android development for Samsung services using latest technologies such as RxJava and Kotlin",
          },
          {
            id: uuidv4(),
            text: "Awared Samsung Citizen award for exceptional commitment to ensuring quality of code",
          },
          {
            id: uuidv4(),
            text: "Followed agile development principles and best coding practices to ensure quality",
          },
        ],
        page: 0,
      },
    ],
  },
  educations: {
    title: "education",
    items: [
      {
        id: uuidv4(),
        course: "Masters in Computer Science",
        institute: "University of Texas, Austin",
        description: "",
        period: "Aug 2020 - Present",
        points: [
          {
            id: uuidv4(),
            text: "Course work included <b>Linear Algebra</b>, <b>Machine Learning</b>, and <b>Deep Learning</b>",
          },
          {
            id: uuidv4(),
            text: "Current CGPA is 3.8/4.0",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        course: "B.Tech in Computer Science",
        institute: "National Institute of Technology, Karnataka",
        description: "",
        period: "July 2013 - May 2017",
        points: [
          {
            id: uuidv4(),
            text: "Course work included <b>Algorithms</b>, <b>Data structure</b>, and <b>Machine Learning</b>",
          },
          {
            id: uuidv4(),
            text: "Secured a CGPA of 8.5/10",
          },
        ],
        page: 0,
      },
    ],
  },
  projects: {
    title: "Projects",
    items: [
      {
        id: uuidv4(),
        title: "Kaggle Data Science Bowl 2019 - Gold Medal",
        period: "Jan 2020 - May 2017",
        description: "",
        points: [
          {
            id: uuidv4(),
            text: "Fifth annual Data Science Bowl, presented by Booz Allen Hamilton and Kaggle",
          },
          {
            id: uuidv4(),
            text: "Predict accuracy group of each child taking an assessment given his/her historical gameplay data",
          },
          {
            id: uuidv4(),
            text: "Created a stable ensemble of LightGBM and XGBoost models",
          },
          {
            id: uuidv4(),
            text: "Placed 75th out of 3497 participants",
          },
        ],
        page: 0,
      },
      {
        id: uuidv4(),
        title: "Kaggle APTOS 2019 Blindness Detection - Silver Medal",
        period: "Jan 2021 - May 2021",
        description: "",
        points: [
          {
            id: uuidv4(),
            text: "Computer vision challenge to identify the stage of Diabetic Retinopathy from images of eye scans",
          },
          {
            id: uuidv4(),
            text: "Created an EffiecientNet-b3 model pre-trained on previous year's competition data and fine-tuned on this year's competition data",
          },
          {
            id: uuidv4(),
            text: "Keras library for training neural networks and imgaug library for image augmentation",
          },
        ],
        page: 0,
      },
    ],
  },
  skills: {
    title: "Skills",
    items: [
      {
        id: uuidv4(),
        name: "Computer vision",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Deep learning",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Machine learning",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Python",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Tensorflow",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Keras",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Android",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "SQL",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Java",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Python",
        page: 0,
      },
      {
        id: uuidv4(),
        name: "Docker",
        page: 0,
      },
    ],
  },
  summary: {
    title: "summary",
    items: [
      {
        id: uuidv4(),
        description: "Software engineer with a passion for everything technology-related. By virtue of working in a product startup that is defining the next phase of computing starting with enterprises, With over 4 years of experience as a software developer in a large organization such as Samsung, I have gained subject matter expertise in machine learning, deep learning, and computer vision. A self-starter, learning everything he can from work as well as at home on his self-assembled deep learning machine.",
        page: 0
      }
    ]
  },
  achievements: {
    title: "achievements",
    items: [
        {
            id: uuidv4(),
            title: "Samsung citizen Award 2018",
            description: "Awarded the prestigious Samsung Citizen Award for going above and beyond for building Samsung Pay Gift Card service",
            page: 0,
        },
        {
            id: uuidv4(),
            title: "Samsung Professional Coding Certificate",
            description: "Received professional level coding certificate for passing the much-coveted coding competition conducted by Samsung for its employees",
            page: 0,    
        }
    ]
  },
  certificates: {
    title: "certificates",
    items: [
        {
            id: uuidv4(),
            title: "Machine Learning by Stanford University on Coursera",
            description: "",
            period: "February 2019",
            page: 0,
        }
    ]
  },
  interests: {
    title: "interests",
    items: [
        {
            id: uuidv4(),
            name: "Photography",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Cooking",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Trekking",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Reading",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Coding",
            page: 0,
        }
    ]
  },
  languages: {
    title: "languages",
    items: [
        {
            id: uuidv4(),
            name: "English",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Malayalam",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Hindi",
            page: 0,
        },
        {
            id: uuidv4(),
            name: "Tamil",
            page: 0,
        }
    ]
}
};

export default resumeContent;