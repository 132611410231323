import classes from "./ProgressBox.module.css";

import ReactDOM from "react-dom";
import { useEffect, useState } from "react";

const ProgressBox = (props) => {
  const contentClass = props.show.showInProgress ? classes.faded : classes.active;
  const messageClass = props.show.messageType === 'success'? classes.messageSuccess: classes.messageFailure;
  const root = document.getElementById("root");
  const [messageVisible, setMessageVisible] = useState(false);

  console.log(props.show);

  useEffect(() => {
    setMessageVisible(true);
    const timer = setTimeout(() => {
      setMessageVisible(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [props.show.message]);
  
  return (
    <div className={classes.container}>
      {ReactDOM.createPortal(
        <div>
          {props.show.showInProgress && (
            <div className={classes.slider}>
              <div className={classes.line}></div>
              <div className={`${classes.subline} ${classes.inc}`}></div>
              <div className={`${classes.subline} ${classes.dec}`}></div>
            </div>
          )}
          {props.show.message != null && messageVisible && (
            <div className={classes.messageContainer}>
              <div className={`${classes.message} ${messageClass}`}>{props.show.message}</div>
            </div>
          )}
        </div>,
        root
      )}
      <div className={contentClass}>{props.children}</div>
    </div>
  );
};

export default ProgressBox;
