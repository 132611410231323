import NavBar from "../components/NavBar";
import classes from "./Home.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import styled from "styled-components";
import resumeHeroImage from "../assets/resume-hero.png";
import resumeTemplate1 from "../assets/templates/template1.png";
import resumeTemplate2 from "../assets/templates/template2.png";
import resumeTemplate3 from "../assets/templates/template3.png";
import resumeTemplate4 from "../assets/templates/template4.png";
import resumeTemplate5 from "../assets/templates/template5.png";
import resumeTemplate6 from "../assets/templates/template6.png";
import resumeEditorIntro from "../assets/editor_intro.mp4";
import CheckIcon from "@material-ui/icons/Check";

const Styles = styled.div`
  .homeContainer {
    background-color: aliceblue;
  }

  .banner {
    background-color: aliceblue;
    margin-bottom: 100px;
    /* margin-top: 150px; */
  }

  .bannerLeftCol {
    /* justify-content: center; */
  }

  .bannerCaption {
    margin-bottom: 20px;
  }

  .build-button {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
    color: #ffffff;
    padding: 15px 20px;
    background-color: #00c8aa;
    border-radius: 5px;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background-color: #01aa90;
    }
  }

  .bannerImage {
    /* box-shadow: 0px 0px 8px #00c8aa; */
  }

  .banner_col1 {
    margin-top: 20px;
  }

  .banner_col2 {
    margin-top: 20px;
  }

  .templates {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .templates_container {
    margin-top: 100px;
    padding-bottom: 20px;
    border-bottom: 1px solid #909090;
  }

  .template-container {
    /* display: flex;
    flex-direction: row; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .template-img {
    /* margin: 30px 10px; */
    margin-bottom: 20px;
    /* border: 1px solid #313B47; */
    box-shadow: 2px 2px 4px #e0e0e0;

    &:hover {
      cursor: pointer;
      border: 2px solid #00c8aa;
    }
  }

  .editor_intro_video {
    width: 100%;
    box-shadow: 2px 2px 15px #00c8aa;
    border-radius: 10px;
  }

  .zero_dollars {
    font-size: 60px;
    width: fit-content;
    margin-right: 25px;
  }

  .footer {
    padding-bottom: 50px;
    padding-top: 20px;
  }

  .footer a {
    text-decoration: none;
    color: #000000;
    &:hover {
      cursor: pointer;
      color: #00c8aa;
    }
  }

  .footer_item {
    padding: 5px;
  }

`;

const Home = (props) => {
  return (
    <Styles>
      <div className="homeContainer">
        <NavBar />
        <Container>
          <Row className="banner row-cols-1 row-cols-md-2 mt-5">
            <Col className="banner_col1 d-flex flex-column align-items-center justify-content-center col-md-4">
              <h2 className="bannerCaption text-center">
                The Most Elegant Resume Builder Out There
              </h2>
              <a href="/editor/new" className="build-button">
                Build your free resume now
              </a>
              {/* <h6>Inplace What-You-See-is-What-You-Get Editor</h6>
              <h6>Write once and style as you like</h6>
              <h6>Unlimited Resume Versions and Downloads</h6> */}
            </Col>
            <Col className="banner_col2 embed-responsive col-md-8">
              <video
                className="editor_intro_video"
                autoPlay
                loop
                muted
                playsInline
                poster={resumeHeroImage}
              >
                <source src={resumeEditorIntro} type="video/mp4" />
              </video>
              {/* <Image className="bannerImage" src={resumeHeroImage} fluid /> */}
            </Col>
          </Row>
          <Row className="row-cols-1 row-cols-md-2 text-center align-items-center">
            <Col className="text-center text-md-end">
                <h3>Your Resume is Entirely Free!</h3>
                <div>No Credit Card Required</div>
            </Col>
            <Col className="text-center text-md-start">
              <div className="d-flex flex-row align-items-center justify-content-center justify-content-md-start">
                <div className="zero_dollars">$0</div>
                <a href="/editor/new" className="build-button">
                  Build your free resume now
                </a>
              </div>
            </Col>
          </Row>
          <div className="templates_container" id="templates">
            <h2 className="text-center">There is a Template for Everyone</h2>
            <Row className="templates row-cols-1 row-cols-md-2 row-cols-lg-3">
              <Col>
                <a href="/editor/new/template1">
                  <Image className="template-img" src={resumeTemplate1} fluid />
                </a>
              </Col>
              <Col>
                <a href="/editor/new/template2">
                  <Image className="template-img" src={resumeTemplate2} fluid />
                </a>
              </Col>
              <Col>
                <a href="/editor/new/template3">
                  <Image className="template-img" src={resumeTemplate3} fluid />
                </a>
              </Col>
              <Col>
                <a href="/editor/new/template4">
                  <Image className="template-img" src={resumeTemplate4} fluid />
                </a>
              </Col>
              <Col>
                <a href="/editor/new/template5">
                  <Image className="template-img" src={resumeTemplate5} fluid />
                </a>
              </Col>
              <Col>
                <a href="/editor/new/template6">
                  <Image className="template-img" src={resumeTemplate6} fluid />
                </a>
              </Col>
            </Row>
          </div>
          <Row className="footer row-cols-1 row-cols-md-2 text-center">
            <Col className="footer_item"><a href="/editor/new">Create Resume</a></Col>
            <Col className="footer_item"><a href="/terms-of-use" target="_blank">Terms of Use</a></Col>
            <Col className="footer_item"><a href="/privacy-policy" target="_blank">Privacy Policy</a></Col>
            <Col className="footer_item">Reach us at hello@dashingresume.com</Col>
          </Row>
        </Container>
      </div>
    </Styles>
  );
};

export default Home;
