import classes from './TemplateDisplay.module.css';
import template_image1 from '../../../assets/templates/template1.png';
import template_image2 from '../../../assets/templates/template2.png';
import template_image3 from '../../../assets/templates/template3.png';
import template_image4 from '../../../assets/templates/template4.png';
import template_image5 from '../../../assets/templates/template5.png';
import template_image6 from '../../../assets/templates/template6.png';

const getTemplateImage = (template_name) => {
  switch (template_name) {
    case "template1": return template_image1;
    case "template2": return template_image2;
    case "template3": return template_image3;
    case "template4": return template_image4;
    case "template5": return template_image5;
    case "template6": return template_image6;
  }
  return template_image1;
}

const TemplateDiplay = (props) => {
  const template = props.template;
  
  return (
    <div className={classes.templateContainer} onClick={() => {props.onSelect(template.id)}}>
      <img src={getTemplateImage(template.name)} className={classes.templateImage}/>
      {/* <span className={classes.templateName}>{template.name}</span> */}
    </div>
  );
};

export default TemplateDiplay;