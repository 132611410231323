import classes from './BlogItem.module.css';

import Image from "react-bootstrap/Image";

const BlogItem = (props) => {
  return (
    <a href={`/blogs/${props.link}`} className={`${classes.anchor}`}>
      <div className={`${classes.blogItemContainer}`}>
        <Image className={`${classes.blogItemImage}`} src={props.image} fluid />
        <div className={`${classes.blogItemRightContainer}`}>
          <p className={`${classes.blogItemTitle}`}>{props.title}</p>
          <p className={`${classes.blogItemDescription}`}>{props.desc}</p>
        </div>
      </div>
    </a>
  );
};

export default BlogItem;