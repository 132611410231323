import React from 'react';

const ResumeEditorContext = React.createContext({
  resumeId: {},
  templateState: {},
  templateStateDispatch: () => {},
  resumeState: {},
  resumeStateDispatch: () => {},
  drawerState: {},
  openDrawer: () => {},
  templates: {},
  templateSelectionHandler: () => {},
  colours: {},
  primaryColourSelectionHandler: () => {},
  fonts: {},
  fontSelectionHandler: () => {},
  fontSizeSelectionHandler: () => {},
  sections: {},
});

export default ResumeEditorContext;