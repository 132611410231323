import classes from "./PanelWidthControllerBlock.module.css";

const PanelWidthControllerBlock = (props) => {
  // const blockClass = props.blockIndex < 10 || props.blockIndex > 30 ? classes.block_invalid : classes.block;
  const blockClass = classes.block
  return (
    <div
      className={blockClass}
      style={{ flex: `${props.flex}%` }}
      onClick={() => {
        props.onBlockClick(props.blockIndex);
      }}
    ></div>
  );
};

export default PanelWidthControllerBlock;
