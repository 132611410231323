import classes from "./FormInput.module.css";

const FormInput = (props) => {

  const alignClass = props.center ? classes.centered : ''

  const changeHandler = (e) => {
    props.onChange({
      type: props.inputId,
      payload: e.target.value,
    })
  }

  return (
    <div className={classes.formInputContainer}>
      <input
        className={`${classes.formInput} ${
          props.error ? classes.formInputError : ""
        } ${alignClass}`}
        type={props.type}
        placeholder={props.placeholder}
        value={props.text}
        onChange={changeHandler}
      >
      </input>
      {props.error && (
        <span className={`${classes.errorMessage} ${alignClass}`}>{props.error}</span>
      )}
    </div>
  );
};

export default FormInput;
