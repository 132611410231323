import { useContext } from "react";
import ResumeEditorContext from "../../store/resume-editor-context";
import classes from "./FontPicker.module.css";

const FontPicker = (props) => {
  const context = useContext(ResumeEditorContext);
  const sizes = [["s", "Small"], ["m", "Medium"], ["l", "Large"]];
  return (
    <div className={classes.fontPickerContainer}>
      <div>
        <div className={classes.title}>Fonts</div>
        {props.fonts.map((fontInfo) => {
          return (
            <div
              key={fontInfo[0]}
              className={classes.fontItem}
              onClick={() => {
                context.fontSelectionHandler(fontInfo);
              }}
            >
              <span style={{ fontFamily: `${fontInfo[0]}, ${fontInfo[1]}` }}>
                {fontInfo[0]}
              </span>
            </div>
          );
        })}
      </div>
      <div>
        <div className={classes.title}>Size</div>
        {sizes.map((size) => {
          return (
            <div
              key={size[0]}
              className={classes.fontItem}
              onClick={() => {
                context.fontSizeSelectionHandler(size[0]);
              }}
            >
              {size[1]}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FontPicker;
