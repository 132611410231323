import classes from './SideDrawer.module.css';

import ResumeEditorContext from "../../store/resume-editor-context";
import { useContext, useEffect, useRef } from 'react';
import TemplatePicker from './TemplatePicker';
import ColourPicker from './ColourPicker';
import FontPicker from './FontPicker';
import LayoutEditor from './LayoutEditor';
import MyResumes from './MyResumes';

const SideDrawer = (props) => {
  const context = useContext(ResumeEditorContext);
  const ref = useRef();
  let openClass = classes.sideDrawer;
  if (context.drawerState) {
    openClass = `${classes.sideDrawer} ${classes.open}`;
  } 
  // else {
  //   openClass = `${classes.sideDrawer} ${classes.close}`;
  // }

  if (context.drawerState === 'myresumes') {
    openClass = `${openClass} ${classes.myresumes}`
  }

  return (
    <div id="slideDrawer" className={openClass}>
      {context.drawerState === 'templates' && <TemplatePicker templates={context.templates} onSelect={context.templateSelectionHandler}/>}
      {context.drawerState === 'layout' && <LayoutEditor/>}
      {context.drawerState === 'colour' && <ColourPicker colours={context.colours} onSelect={context.primaryColourSelectionHandler}/>}
      {context.drawerState === 'font' && <FontPicker fonts={context.fonts} onSelect={context.fontSelectionHandler}/>}
      {context.drawerState === 'myresumes' && <MyResumes onSelect={context.fontSelectionHandler}/>}
    </div>
  );
};

export default SideDrawer;