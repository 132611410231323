import NavBar from "../../../components/NavBar";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import joeyResume from "../../../assets/blogs/joey_tribbiani_resume.png";
import JoeyTribbianiResume from './JoeyTribbianiResume';
import BlogItem from "../components/BlogItem";

const Styles = styled.div`
  .blogContainer {
    background-color: #ffffff;
    background-color: aliceblue;
    padding-top: 50px;
    padding-bottom: 100px;
    font-family: "Roboto", sans-serif;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
    min-height: 1200px;
  }

  h1 {
    margin-top: 50px;
  }

  .itemContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .itemImage {
    width: 200px;
  }
`;

const PopularResumes = (props) => {
  console.log(props.article);
  const contents = [
    {
      title: "Joey Tribbiani's Resume",
      description: "Not that the popular character needs a resume, we wondered what it would look like if he had one!",
      image: joeyResume,
      link: "popular-resumes/joey-tribbiani",
      article: JoeyTribbianiResume
    },
  ];

  if (props.article) {
    switch(props.article) {
      case "joey-tribbiani":
        return <JoeyTribbianiResume/>
      default:
        console.log('Article is not valid');
    }
  }

  return (
    <Styles>
      <NavBar />
      <div className="blogContainer">
        <Container className="">
          <Row className="row-cols-2">
            {contents.map((content) => {
              return (
                <BlogItem link={content.link} image={content.image} title={content.title} desc={content.description}/>
              );
            })}
          </Row>
        </Container>
      </div>
    </Styles>
  );
};

export default PopularResumes;
