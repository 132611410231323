import classes from "./NavBar.module.css";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import React from "react";
import styled from 'styled-components';

const Styles = styled.div`
  .build-button {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
    color: #ffffff;
    padding: 15px 20px;
    background-color: #00c8aa;
    border-radius: 5px;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background-color: #01aa90;
    }
  }
`;

const CreateResumeButton = (props) => {
  return (
    <Styles>
      <a href="/editor/new" className="build-button">
        Build your free resume now
      </a>
    </Styles>
  );
};

export default CreateResumeButton;
