import classes from './FormButton.module.css';

const FormButton = (props) => {
  const themeClass = props.theme === 'theme2' ? classes.theme2 : classes.theme1;
  return (
    <div className={`${classes.formButton} ${themeClass}`} onClick={props.onClick}>
      {props.text}
    </div>
  );
};

export default FormButton;