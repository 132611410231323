import classes from "./Skill.module.css";

import { useContext } from "react";
import EditableSection from "../primitives/EditableSection";
import InputField from "../primitives/InputField";

import ResumeContext from "../../store/resume-builder-context";

const Skill = (props) => {
  const skill = props.data;
  const context = useContext(ResumeContext);
  const resumeState = context.resumeState;
  const templateState = context.templateState;

  const removeHandler = () => {
    context.resumeStateDispatch({
      type: "removeSkill",
      payload: skill.id,
    });
  };

  const changeHandler = (id, value) => {
    switch (id) {
      case "name":
        skill.name = value;
        break;
      default:
        break;
    }
    console.log(skill);
    context.resumeStateDispatch({
      type: "updateSkill",
      payload: skill,
    });
  };

  const bgcolor = context.templateState.primaryColor;

  const style = props.theme === 'outline' ? {border: `1px solid ${bgcolor}`, color: '#000000'} : { backgroundColor: bgcolor, color: '#ffffff' }; 

  return (
    <EditableSection
      isRemovable={true}
      highlight={false}
      type="list"
      onRemove={removeHandler}
      page={props.page}
      panel={props.panel}
      section={props.section}
      id={props.id}
      key={props.id}
    >
      <div
        className={classes.skill}
        key={skill.id}
        style={style}
      >
        <InputField
          placeholder="Item"
          optional={false}
          fontWeight={500}
          fontSize={templateState.fontSize.normal}
          color={style.color}
          align="center"
          id="name"
          onChange={changeHandler}
        >
          {skill.name}
        </InputField>
      </div>
    </EditableSection>
  );
};

export default Skill;
