import axios from "axios";

const API = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

API.interceptors.request.use(
  config => {
    try {
      const userFromLocalStorage = localStorage.getItem("user");
      if (userFromLocalStorage && !config.headers['Authorization']) {
        const user = JSON.parse(userFromLocalStorage);
        config.headers['Authorization'] = `Bearer ${user.authtoken}`
      }
    } catch(err) {

    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {return response}, 
  async (error) => {
    const originalRequest = error.config;
    console.log('intercepted');
    if (error.response.status === 401 && originalRequest.url !== '/auth/token') {
      let userFromLocalStorage = localStorage.getItem("user");
      if (userFromLocalStorage) {
        console.log(`user exists in local storage: ${userFromLocalStorage}`);
        userFromLocalStorage = JSON.parse(userFromLocalStorage);
        const response = await refreshAuthToken(userFromLocalStorage.refreshToken);
        const user = response.data;
        localStorage.setItem("user", JSON.stringify(user));
        originalRequest.headers['Authorization'] = `Bearer ${user.authtoken}`
        return API(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export const registerSendCode = (name, email) =>
  API.post("/auth/register/sendcode", { name, email });
export const register = (name, email, password, code) =>
  API.post("/auth/register", { name, email, password, code });
export const signin = (email, password) =>
  API.post("/auth/login", { email, password });
export const googleSignin = (name, email, token) =>
  API.post("/auth/googlelogin", { name, email, token });
export const refreshAuthToken = (refreshToken) =>
  API.post("/auth/token", { token: refreshToken });
export const forgotPasswordSendCode = (email) =>
  API.post("/auth/forgotpassword/sendcode", { email });
export const forgotPassword = (email, password, code) =>
  API.post("/auth/forgotpassword", { email, password, code });

export const signout = () =>
  API.post(
    "/auth/logout",
    {},
  );
export const saveResume = (resumeId, resumeData, templateState) =>
  API.post(
    "/resumeapp/save",
    {
      resumeId: resumeId,
      data: resumeData,
      template: templateState,
    }
  );
export const loadResume = (resumeId, authtoken) =>
  API.get(
    "/resumeapp/load",
    {
      params: {
        resumeId: resumeId
      },
      headers: {
        Authorization: `Bearer ${authtoken}`
      }
    }
  );
export const loadMyResumes = () =>
  API.get(
    "/resumeapp/load",
  );
export const deleteResume = (resumeId) =>
  API.delete(
    "/resumeapp/",
    {
      params: {
        resumeId: resumeId
      }
    }
  );

export const downloadResume = (resumeId) =>
  API.get(
    "/resumeapp/download",
    {
      params: {
        resumeId: resumeId
      },
      headers: { 
        Accept: 'application/pdf', 
      },
      responseType: 'arraybuffer',
    }
  );
