import classes from "./Interest.module.css";

import { useContext } from "react";
import EditableSection from "../primitives/EditableSection";
import InputField from "../primitives/InputField";

import ResumeContext from "../../store/resume-builder-context";

const Interest = (props) => {
  const interest = props.data;
  const context = useContext(ResumeContext);
  const resumeState = context.resumeState;
  const templateState = context.templateState;

  const removeHandler = () => {
    context.resumeStateDispatch({
      type: "removeInterest",
      payload: interest.id,
    });
  };

  const changeHandler = (id, value) => {
    switch (id) {
      case "name":
        interest.name = value;
        break;
      default:
        break;
    }
    console.log(interest);
    context.resumeStateDispatch({
      type: "updateInterest",
      payload: interest,
    });
  };

  const bgcolor = context.templateState.primaryColor;

  return (
    <EditableSection
      isRemovable={true}
      highlight={false}
      type="list"
      onRemove={removeHandler}
      page={props.page}
      panel={props.panel}
      section={props.section}
      id={props.id}
      key={props.id}
    >
      <div
        className={classes.interest}
        key={interest.id}
        style={{ border: `1px solid ${bgcolor}`}}
      >
        <InputField
          placeholder="Item"
          optional={false}
          fontWeight={500}
          fontSize={templateState.fontSize.normal}
          align="center"
          id="name"
          onChange={changeHandler}
        >
          {interest.name}
        </InputField>
      </div>
    </EditableSection>
  );
};

export default Interest;
