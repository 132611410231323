import classes from "./PersonalDetails.module.css";

import EditableSection from "../primitives/EditableSection";
import InputField from "../primitives/InputField";

import ResumeContext from "../../store/resume-builder-context";
import { useContext } from "react";

const PersonalDetails = (props) => {
  const personalDetails = props.data;
  const context = useContext(ResumeContext);
  const resumeState = context.resumeState;
  const templateState = context.templateState;

  let containerClass = classes.containerTheme1;
  let firstBoxClass = classes.firstBoxTheme1;
  let secondBoxClass = classes.secondBoxTheme1;

  switch (props.theme) {
    case "theme1": {
      containerClass = classes.containerTheme1;
      firstBoxClass = classes.firstBoxTheme1;
      secondBoxClass = classes.secondBoxTheme1;  
      break;
    }
    case "theme2": {
      containerClass = classes.containerTheme2;
      firstBoxClass = classes.firstBoxTheme2;
      secondBoxClass = classes.secondBoxTheme2;  
      break;
    }
    case "theme3": {
      containerClass = classes.containerTheme3;
      firstBoxClass = classes.firstBoxTheme3;
      secondBoxClass = classes.secondBoxTheme3;  
      break;
    }
    default: {
      containerClass = classes.containerTheme1;
      firstBoxClass = classes.firstBoxTheme1;
      secondBoxClass = classes.secondBoxTheme1;
    }
  }

  const changeHandler = (id, value) => {
    switch (id) {
      case "name":
        personalDetails.name = value;
        break;
      case "title":
        personalDetails.title = value;
        break;
      case "pitch":
        personalDetails.pitch = value;
        break;
      case "email":
        personalDetails.email = value;
        break;
      case "phone":
        personalDetails.phone = value;
        break;
      default:
        for (var i in personalDetails.links) {
          if (personalDetails.links[i].id === id) {
            personalDetails.links[i].text = value;
          }
        }
    }
    console.log(personalDetails);
    context.resumeStateDispatch({
      type: "updatePersonalDetails",
      payload: personalDetails,
    });
  };

  return (
    <EditableSection
      isRemovable={false}
      highlight={false}
      page={props.page}
      section={props.section}
      id={props.id}
      key={props.id}
    >
      <div className={containerClass}>
        <div className={firstBoxClass}>
          <InputField
            placeholder="Name"
            optional={false}
            fontWeight={500}
            fontSize={templateState.fontSize.xxlarge}
            color={context.templateState.primaryColor}
            id="name"
            onChange={changeHandler}
          >
            {personalDetails.name}
          </InputField>
          <InputField
            placeholder="Professional Title"
            optional={false}
            fontWeight={400}
            fontSize={templateState.fontSize.large}
            id="title"
            onChange={changeHandler}
          >
            {personalDetails.title}
          </InputField>
          <InputField
            placeholder="Self Pitch"
            optional={true}
            fontWeight={400}
            fontSize={templateState.fontSize.normal}
            id="pitch"
            onChange={changeHandler}
          >
            {personalDetails.pitch}
          </InputField>
        </div>
        <div className={secondBoxClass}>
          <InputField
            placeholder="email"
            optional={false}
            fontWeight={400}
            fontSize={templateState.fontSize.normal}
            right={true}
            id="email"
            onChange={changeHandler}
          >
            {personalDetails.email}
          </InputField>
          <InputField
            placeholder="phone"
            optional={false}
            fontWeight={400}
            fontSize={templateState.fontSize.normal}
            right={true}
            id="phone"
            onChange={changeHandler}
          >
            {personalDetails.phone}
          </InputField>
          {personalDetails.links.map((link) => {
            return (
              <InputField
                placeholder="link"
                optional={true}
                fontWeight={400}
                fontSize={templateState.fontSize.normal}
                right={true}
                key={link.id}
                id={link.id}
                onChange={changeHandler}
              >
                {link.text}
              </InputField>
            );
          })}
        </div>
      </div>
    </EditableSection>
  );
};

export default PersonalDetails;
