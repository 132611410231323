import ResumeDisplay from '../primitives/ResumeDisplay';
import TemplateDiplay from './TemplateDisplay';
import classes from './TemplatePicker.module.css';

const TemplatePicker = (props) => {
  return (
    <div className={classes.templateContainer}>
      {
        props.templates.map((template, index) => {
          // return <ResumeDisplay key={template.id} templateState={template} noEdit={true} showcase={true} scale={0.3}/>
          return <TemplateDiplay key={template.id} template={template} onSelect={props.onSelect}/>
        })
      }
    </div>
  );
};

export default TemplatePicker;