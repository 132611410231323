import classes from "./MyResumeDisplay.module.css";
import ResumeEditorContext from "../../store/resume-editor-context";
import AppContext from "../../store/app-context";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import ResumeDisplay from "../primitives/ResumeDisplay";
import { useContext, useState } from "react";
import * as api from "../../api/index.js";

const MyResumeDisplay = (props) => {
  const resume = props.resume;
  const history = useHistory();
  const editorContext = useContext(ResumeEditorContext);
  const appContext = useContext(AppContext);
  const [deleteClicked, setDeleteClicked] = useState(false);

  const resumeClickHandler = (resumeId) => {
    if (editorContext.resumeId != resumeId) {
      history.push(`/editor/${resumeId}`);
    }
  };

  const resumeDeleteHandler = async (resumeId) => {
    console.log(`delete resume ${resumeId}`);
    api.deleteResume(resumeId);
    props.onDelete(resumeId);
    if (editorContext.resumeId === resumeId) {
      history.push('/editor/new');
    }
  };

  return (
    <div
      className={classes.resumeDisplayContainer}
      onClick={() => {
        resumeClickHandler(resume.id);
      }}
    >
      <ResumeDisplay
        key={resume.id}
        templateState={resume.templateState}
        resumeState={resume.resumeState}
        showcase={true}
        noEdit={true}
      />
      <span className={classes.deleteContainer}>
        <DeleteIcon
          className={classes.deleteButton}
          style={{ fontSize: 80 }}
          onClick={(event) => {
            event.stopPropagation();
            setDeleteClicked(true);
            // resumeDeleteHandler(resume.id);
          }}
        />
      </span>
      {deleteClicked && (
        <div className={classes.deleteOverlay}>
          <div className={classes.deleteConfirmContainer}>
            <div>Permanently remove Resume?</div>
            <div className={classes.deleteConfirm}>
              <span
                className={classes.deleteConfirmNo}
                onClick={(event) => {
                  event.stopPropagation();
                  setDeleteClicked(false);
                }}
              >
                No
              </span>
              <span className={classes.deleteConfirmYes}
                onClick={
                  (event) => {
                    event.stopPropagation();
                    resumeDeleteHandler(resume.id);
                  }
                }
              >Yes</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyResumeDisplay;
