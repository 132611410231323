import classes from "./ResumeEditor.module.css";

import ResumeDisplay from "../primitives/ResumeDisplay";
import PanelWidthController from "./PanelWidthController";
import { useContext, useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { Prompt } from 'react-router';

import { v4 as uuidv4 } from "uuid";
import EditBar from "./EditBar";
import AppContext from "../../store/app-context";
import ResumeEditorContext from "../../store/resume-editor-context";
import MediumEditor from "medium-editor";
import ControlBar from "./ControlBar";
import * as resumeUtil from "./ResumeUtil";
import * as reducers from "./ResumeReducers";
import React from "react";
import NavBar from "../../../components/NavBar";

const ResumeEditor = (props) => {
  const appContext = useContext(AppContext);
  const params = useParams();
  const resumeId = params.resumeId;
  const templateId = params.templateId;
  // console.log(templateId);
  const resumeInitialState = resumeUtil.initResumeState();
  // const resumeInitialState = resumeUtil.initResumeStateDummy();
  const templateInitialState = resumeUtil.initTemplateState(templateId);

  const templates = resumeUtil.templates;
  const colours = resumeUtil.colours;
  const fonts = resumeUtil.fonts;
  const sections = resumeUtil.sections;

  const [drawerState, openDrawer] = useState(null);
  const [templateState, templateStateDispatch] = useReducer(
    reducers.templateReducer,
    templateInitialState
  );

  console.log(templateState);

  const panelWidthChangeHandler = (flex) => {
    templateStateDispatch({
      type: "updatePanelWidth",
      payload: flex,
    });
  };

  const templateSelectionHandler = (templateId) => {
    templateStateDispatch({
      type: "selectTemplate",
      payload: templateId,
    });
  };

  const primaryColourSelectionHandler = (colour) => {
    templateStateDispatch({
      type: "selectColour",
      payload: colour,
    });
  };

  const fontSelectionHandler = (fontInfo) => {
    templateStateDispatch({
      type: "selectFont",
      payload: fontInfo,
    });
  };

  const [resumeState, resumeStateDispatch] = useReducer(
    reducers.resumeReducer,
    resumeInitialState
  );

  console.log(resumeState);

  const fontSizeSelectionHandler = (size) => {
    templateStateDispatch({
      type: "selectFontSize",
      payload: size,
    });
  };

  const alertUnsavedChanges = e => {
    if (resumeState.unsavedChanges) {
      e.preventDefault();
      e.returnValue = 'Are you sure? You have unsaved changes.';
      return 'Are you sure? You have unsaved changes.'
    } else {
      return undefined;
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', alertUnsavedChanges);
    return () => {
      window.removeEventListener('beforeunload', alertUnsavedChanges);
    }
  }, [alertUnsavedChanges]);

  useEffect(() => {
    if (!appContext.getUser() || resumeId === "new") {
      return;
    }
    const load = async () => {
      appContext.showProgress(true);
      try {
        const user = appContext.getUser();
        const response = await resumeUtil.loadData(resumeId, user.authtoken);
        appContext.showProgress(false);
        if (response) {
          resumeStateDispatch({
            type: "initialize",
            payload: response.resumeState,
          });
          templateStateDispatch({
            type: "initialize",
            payload: response.templateState,
          });
        }
        console.log(`response of load ${response}`);
        appContext.showProgress(false);
      } catch (error) {
        if (error.response.data.hasOwnProperty("message")) {
          appContext.showProgress(
            false,
            error.response.data.message,
            "failure"
          );
        } else {
          appContext.showProgress(false, "Failed to load resume", "failure");
        }
      } finally {
      }
    };
    load();
  }, [resumeId]);

  useEffect(() => {
    resumeStateDispatch({
      type: "pageStateRecompute",
    });
  }, [templateState]);

  useEffect(() => {
    let mediumEditor = new MediumEditor(".inputfield_editable", {
      toolbar: {
        /* These are the default options for the toolbar,
             if nothing is passed this is what is used */
        allowMultiParagraphSelection: true,
        buttons: ["bold", "italic", "underline", "anchor"],
        diffLeft: 0,
        diffTop: -10,
        firstButtonClass: "medium-editor-button-first",
        lastButtonClass: "medium-editor-button-last",
        relativeContainer: null,
        standardizeSelectionStart: false,
        static: false,
        /* options which only apply when static is true */
        align: "center",
        sticky: false,
        updateOnEmptySelection: false,
      },
      anchor: {
        linkValidation: true,
      },
      placeholder: false,
      disableReturn: true,
    });
  });

  return (
    <React.Fragment>
      <Prompt
        when={resumeState.unsavedChanges}
        message='You have unsaved changes. Are you sure you want to leave?'
      />
      <ResumeEditorContext.Provider
        value={{
          resumeId,
          templateState,
          templateStateDispatch,
          resumeState,
          resumeStateDispatch,
          drawerState,
          openDrawer,
          templates,
          templateSelectionHandler,
          colours,
          primaryColourSelectionHandler,
          fonts,
          fontSelectionHandler,
          fontSizeSelectionHandler,
          sections,
        }}
      >
        <div className={classes.background}>
          <div className={`${classes.logo} d-none d-lg-block`}><a href='/'>DASHINGRESUME</a></div>
          {/* <NavBar/> */}
          <div className={classes.editorWrapper}>
            <div className={classes.editorContainer}>
              <div className={classes.mainSection}>
                {
                  <div className={classes.topToolsPanel}>
                    <div className={classes.editBarsContainer}>
                      <EditBar />
                      <ControlBar />
                    </div>
                    {templateState.panels.length > 1 && (
                      <PanelWidthController
                        flex={50}
                        blockCount={40}
                        onPanelWidthChange={panelWidthChangeHandler}
                      />
                    )}
                  </div>
                }
                <div className={classes.display}>
                  <ResumeDisplay
                    templateState={templateState}
                    resumeState={resumeState}
                    resumeStateDispatch={resumeStateDispatch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ResumeEditorContext.Provider>
    </React.Fragment>
  );
};

export default ResumeEditor;
