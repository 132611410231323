import React from 'react';

const AppContext = React.createContext({
  getUser: () => {},
  setUser: () => {},
  loggedIn: false,
  openAuthWindow: () => {},
  showProgress: () => {},
});

export default AppContext;