import classes from "./Experience.module.css";

import { useContext, useEffect, useRef, useState } from "react";
import EditableSection from "../primitives/EditableSection";
import InputField from "../primitives/InputField";
import ListInputField from "../primitives/ListInputField";
import ListInputFieldItem from "../primitives/ListInputFieldItem";

import ResumeContext from "../../store/resume-builder-context";

const Experience = (props) => {
  const experience = props.data;
  const context = useContext(ResumeContext);
  const resumeState = context.resumeState;
  const templateState = context.templateState;
  const [isSubSectionEditing, setSubSectionEditState] = useState(false);

  const removeHandler = () => {
    context.resumeStateDispatch({
      type: "removeExperience",
      payload: experience.id,
    });
  };

  const addPointHandler = () => {
    context.resumeStateDispatch({
      type: "addExperiencePoint",
      payload: experience.id,
    });
  };

  const changeHandler = (id, value) => {
    switch (id) {
      case "position":
        experience.position = value;
        break;
      case "company":
        experience.company = value;
        break;
      case "period":
        experience.period = value;
        break;
      case "description":
        experience.description = value;
        break;
      default:
        for (var i in experience.points) {
          if (experience.points[i].id === id) {
            experience.points[i].text = value;
          }
        }
    }
    console.log(experience);
    context.resumeStateDispatch({
      type: "updateExperience",
      payload: experience,
    });
  };

  useEffect(() => {
    let needCleaning = false;
    for (var i in experience.points) {
      if (experience.points[i].text === "") {
        needCleaning = true;
      }
    }
    if (!isSubSectionEditing && needCleaning) {
      context.resumeStateDispatch({
        type: "cleanExperience",
        payload: experience.id,
      });
    }
  }, [isSubSectionEditing]);

  return (
    <EditableSection
      isRemovable={true}
      highlight={true}
      onRemove={removeHandler}
      setSubSectionEditState={setSubSectionEditState}
      page={props.page}
      panel={props.panel}
      section={props.section}
      id={props.id}
      key={props.id}
    >
      <div className={classes.experience}>
        <InputField
          placeholder="Title / Position"
          optional={false}
          capitalize={true}
          fontWeight={700}
          fontSize={templateState.fontSize.medium}
          id="position"
          onChange={changeHandler}
        >
          {experience.position}
        </InputField>

        <InputField
          placeholder="Company"
          optional={false}
          fontWeight={400}
          fontSize={templateState.fontSize.medium}
          id="company"
          onChange={changeHandler}
        >
          {experience.company}
        </InputField>

        <InputField
          placeholder="period"
          optional={false}
          fontWeight={400}
          fontSize={templateState.fontSize.small}
          id="period"
          onChange={changeHandler}
        >
          {experience.period}
        </InputField>

        <InputField
          placeholder="Description (optional)"
          optional={true}
          fontWeight={300}
          fontSize={templateState.fontSize.small}
          id="description"
          onChange={changeHandler}
        >
          {experience.description}
        </InputField>

        <ListInputField onAdd={addPointHandler}>
          {experience.points.map((point) => {
            return (
              <ListInputFieldItem key={point.id}>
                <InputField
                  id={point.id}
                  placeholder="Achievements"
                  fontSize={templateState.fontSize.normal}
                  onChange={changeHandler}
                >
                  {point.text}
                </InputField>
              </ListInputFieldItem>
            );
          })}
        </ListInputField>
      </div>
    </EditableSection>
  );
};

export default Experience;
