import NavBar from "../../components/NavBar";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import PopularResumes from './popular-resumes/PopularResumes';

const Styles = styled.div`
  .blogContainer {
    background-color: #ffffff;
    padding-top: 50px;
    padding-bottom: 100px;
    font-family: "Roboto", sans-serif;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  p {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.5em;
  }

  h1 {
    margin-top: 50px;
  }

  .itemContainer {
    display: flex;
    flex-direction: row;
  }
`;

const Blogs = (props) => {
  const params = useParams();
  const category = params.category;
  const article = params.article;

  console.log(category);
  console.log(article);

  switch(category) {
    case "popular-resumes":
      return <PopularResumes article={article} />
    default:
      return <PopularResumes article={article} />
  }
};

export default Blogs;
