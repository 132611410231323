import classes from "./NavBar.module.css";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import React from "react";
import styled from 'styled-components';

const Styles = styled.div`
  .navbar {
    background-color: aliceblue;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .navbar-brand, .navbar-nav .nav-link {
    color: #000;
    &:hover {
      color: #00c8aa;
    }
  }

  /* .navbar-collapse {
    justify-content: flex-end;
  } */
`;

const NavigationBar = (props) => {
  return (
    <Styles>
      <Navbar expand="lg" sticky="top">
        <Container>
          <Navbar.Brand href="/">DASHINGRESUME</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/#templates">Templates</Nav.Link>
              <Nav.Link href="mailto: hello@dashingresume.com">Contact Us</Nav.Link>
              {/* <Nav.Link href="#link">Pricing</Nav.Link> */}
              <NavDropdown title="Blogs" id="basic-nav-dropdown">
                <NavDropdown.Item href="/blogs/popular-resumes">Popular resumes</NavDropdown.Item>
                {/* <NavDropdown.Item href="/privacy-policy">Privacy Policy</NavDropdown.Item> */}
                {/* <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item> */}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Styles>
  );
};

export default NavigationBar;
