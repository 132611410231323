import NavBar from "../components/NavBar";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Styles = styled.div`
  .tosContainer {
    background-color: #FFFFFF;
    padding-top: 50px;
    padding-bottom: 100px;
    font-family: "Roboto", sans-serif;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  p {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.5em;
  }

  h2 {
    margin-top: 50px;
  }
`;

const ToS = (props) => {
  return (
    <Styles>
      <NavBar />
      <div className="tosContainer">
        <Container className="">
          <h1>Terms of Use</h1>
          <p>Welcome to Dashingresume and thanks for the support!</p>
          <p>
            Please read these Terms of Use ("Terms”, "Terms and Conditions”)
            before using Dashingresume (the "Website”, the "Software”, the
            "Service”).
          </p>
          <p>
            Your access to use of the Service is conditioned on your acceptance
            of and compliance with these Terms of Use. The Terms apply to all
            visitors, users and others who access or use Dashingresume.
          </p>
          <p>
            By accessing or using Dashingresume, you agree to be bound by these
            Terms of Use. If you disagree with any part of the Terms, we kindly
            ask you to stop using Dashingresume.
          </p>
          <h2>The Content</h2>
          <p>All of the information, content, operations, functionality and software displayed on, transmitted through, or used in connection with Dashingresume, including for example: directories, guides, articles, reviews, text, photographs, images, illustrations, video, source and object code, software, data, and all other matters related to Dashingresume, including without limitation, the selection and arrangement of the aforementioned and the "look and feel” of Dashingresume (collectively, the "Content”), are protected under applicable copyrights and other proprietary (including but not limited to intellectual property) rights and are the intellectual property of Dashingresume.</p>
          <p>You may not frame or utilize framing techniques that involve any trademark, logo, copyrighted material or other proprietary information (including images, text, page layout, or form) of any portion of Dashingresume without the express written consent of an authorized person from Dashingresume. In addition, you agree not to decompile, reverse engineer or disassemble any software or other products or processes accessible through Dashingresume, not to insert any code or product or manipulate the content of Dashingresume in any way that affects the user's experience, and not to use any data mining, robots, cancelbots, Trojan horse, or any data gathering or extraction method in connection with your use of Dashingresume.</p>
          <p>Dashingresume respects the intellectual property of others. If you believe that your work has been copied in a way that constitutes copyright infringement or if you are aware of any infringing material placed on Dashingresume, please contact us.</p>
          <p>The information available on Dashingresume is for general information purpose only. While we strive to do thorough research and keep the information all the time up-to-date and correct, we do not guarantee the full completeness, accuracy, reliability, suitability or availability concerning the information. Any reliance on the information available is therefore at your own risk.</p>

          <h2>Acceptable Use</h2>
          <p>You must not use Dashingresume in any way that causes, or may cause, damage to Dashingresume or impairment of the availability or accessibility of Dashingresume, or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>
          <p>You must not use Dashingresume to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</p>
          <p>You must not use Dashingresume in a way that does not respect the copyright and proprietary rights of other individuals or companies/ organizations.</p>
          <p>You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or concerning Dashingresume without an authorized person's express written consent from Dashingresume.</p>
          <p>You must not use Dashingresume in order to create a résumé, cover letter, online portfolio for a third party since all the documents include personal information unless you are using an explicit Business/Enterprise account, which is different from the regular account and the regular premium account. Dashingresume was developed for helping individual persons get their dream job or improve their career. You must not sell/trade the layout or documents from/generated by Dashingresume to any third party. For B2B (as "Business to Business”) services there are other "Terms of Use” and "Privacy Policy” that must be accepted.</p>

          <p>You must not use Dashingresume to transmit or send unsolicited commercial communications.</p>
          <p>You must not use Dashingresume for any purposes related to marketing without an authorized person's express written consent from Dashingresume.</p>
          <p>In order to use Dashingresume you must be at least 18 years old.</p>

          <h2>Security</h2>
          <p>Dashingresume has implemented commercially reasonable technical and organizational measures designed to secure your private information from accidental loss and unauthorized access, use, alteration or disclosure. However, we cannot guarantee that unauthorized third parties will never be able to defeat those measures or use your private information for improper purposes.</p>

          <h2>Links To Other Software/Websites/Services</h2>
          <p>Dashingresume may contain links to third-party software, websites or services that are not owned or controlled by Dashingresume. Dashingresume has no control over and assumes no responsibility for, the content, privacy policies, or practices of any third party software, websites or services.</p>
          <p>You further acknowledge and agree that Dashingresume shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such software, websites or services.</p>
          <p>Additionally, your dealings with or participation in promotions of advertisers found on Dashingresume, including payment and delivery of goods, and any other terms (such as warranties) are solely between you and such advertisers. You agree that Dashingresume shall not be responsible for any loss or damage of any sort relating to your dealings with such advertisers.</p>
          <p>We cannot be held liable for anything that happens as a result of malicious software, virus, etc. that might affect your computer and is interfering with Dashingresume showing corrupt data that you might click on and as an effect put your information at risk. We strongly advise you to protect your device with an antivirus and do a monthly checkup of your device.</p>
          <p>Our team always strives to bring you professional and researched content to help you at all the steps of your career, and in some cases, the content will be provided by third-party software, websites or services.</p>
          <p>We strongly advise you to read the terms of use and privacy policies of any third-party software, websites or services that you visit.</p>

          <h2>Warranty, Refunds and Premium Account</h2>
          <p>Dashingresume strives to provide the best service and products, but you understand and agree that Dashingresume is provided "as is” and "as available” without any representations or warranties, express or implied. Without prejudice, Dashingresume does not warrant that:</p>
          <ul>
            <li>Dashingresume will be constantly available, or available at all.</li>
            <li>The information on Dashingresume is complete, true, accurate or non-misleading.</li>
            <li>That Dashingresume will meet your requirements.</li>
            <li>That Dashingresume will be available at any particular time or location, uninterrupted or secure.</li>
            <li>That Dashingresume will be 24/7 active all the time.</li>
            <li>Your data will be saved all the time in your account, and that data loss will never occur. However, we will do our best to make sure all the information is saved in your account for a flawless experience. Data loss is very unlikely.</li>
          </ul>
          <p>Dashingresume reserves the right to change the structure and design of the résumé, cover letter or other services when they can be improved, and there are benefits for the users. These changes are made according to our users' feedback, research and after consulting HR experts.</p>
          <p>No refunds will be provided since most of the premium features related to Dashingresume can be tested for free. If by any chance you will encounter various problems, and you're not happy with the final result, do not hesitate to contact us at hello@dashingresume.com, and our team will get back to you on what could be done in your case.</p>
          <p>After purchasing a premium account, the receipt will be sent to the email address provided when you set up your account, within 48 hours of making the purchase. If you have not received your receipt within 48 hours of booking, please check your spam or junk email folder or filter and then contact us. It is your responsibility to ensure that your email is set up to allow you to receive your bill, and we cannot accept any liability for any consequences of your not doing so. However, sometimes technical problems may occur from our side, and in this case, you can request your receipt by sending us an email at hello@dashingresume.com.</p>
          <p>In the unfortunate event of the software, apps, applications or servers crashing or having technical problems during your purchase which will result in your premium account not being activated, please email us at hello@dashingresume.com, and the problem will be investigated. This will result in different compensations or refunds in the case we find the problem was caused by our part.</p>
          <p>When you choose to extend the Premium account by any period, this will be extended according to the calculations made by our databases and the number of days per month can range from 28 to 31, depending on the particular month.</p>
          <p>We reserve the right to delete also the Premium Accounts if the user is misusing Dashingresume or doing malicious activities, but we doubt this will be the case.</p>
          <p>Your private details are encrypted between the client (YOU, as a user) and the server using an SSL certificate, to protect your data.</p>
          <p>We reserve the right to change the price of our services when introducing new promotions, special packages or when changes are being made regarding taxes, VAT regulations or external factors such as inflation/deflation.</p>
          <h2>Governing Law</h2>
          <p>These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
          <h2>Changes</h2>
          <p>Occasionally Dashingresume might change the terms. When such changes are made, Dashingresume will make sure to provide you with prominent notice as appropriate under the circumstances, e.g., by displaying a prominent notice within Dashingresume or by sending you an email. In some cases, we will notify you in advance. Please, therefore, make sure you read any such notice carefully.</p>
          <h2>Breaches of these terms of use</h2>
          <p>Without prejudice to Dashingresume’s other rights under these terms and use, if you breach these terms of use in any way, Dashingresume may take such action as Dashingresume deems appropriate to deal with the breach, including suspending your access to Dashingresume, prohibiting you from accessing Dashingresume, blocking computers using your IP address from accessing Dashingresume, contacting your internet service provider to request that they block your access to Dashingresume and/or bringing court proceedings against you.</p>
          <h2>Termination</h2>
          <p>We may terminate or suspend access to Dashingresume immediately, for both Basic and Premium membership users, without prior notice or liability if you breach the Terms, or for any other reason, we consider justified. All provisions of the Terms shall survive termination, including but not limited to: ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
          <p>Dashingresume reserves the right of deleting an account if there are signs of suspicious, unusual or harmful activities, therefore creating a potential danger for Dashingresume. However, we highly trust and respect our users and we do not believe it will be the case to terminate someone’s account.</p>
          <h2>Contact Us</h2>
          <p>Dashingresume details:</p>
          <ul>
            <li>If you feel like something is missing or misleading in our Terms of Use, please feel free to notify us.</li>
            <li>You can contact us by sending an email to hello@dashingresume.com.</li>
            <li>The Terms of Use was last modified on 10/September/2021.</li>
          </ul>
        </Container>
      </div>
    </Styles>
  );
};

export default ToS;
