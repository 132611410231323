import classes from './AddButton.module.css';

const AddButton = (props) => {
  let sizeClass = classes.big;
  switch (props.size) {
    case 'small':
      sizeClass = classes.small;
      break;
    case 'ultrabig':
      sizeClass = classes.ultrabig;
      break;
  }
  return (
    <div className={`${classes.lineContainer}`}>
      <div className={`${classes.plusButton} ${sizeClass}`} onClick={props.onAdd}><span>+</span></div>
      <div className={classes.line}>

      </div>
    </div>
  );
};

export default AddButton;