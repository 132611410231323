import { useState } from "react";
import "./App.css";
import AuthenticateAccount from "./resume/components/editor/AuthenticateAccount";
import ResumeEditor from "./resume/components/editor/ResumeEditor";
import AppContext from "./resume/store/app-context";
import ProgressBox from "./ProgressBox";
import ResumePrint from "./resume/components/editor/ResumePrint";

import { Route, Redirect, Switch } from "react-router-dom";
import Home from "./pages/Home";
import ToS from "./pages/ToS";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Blogs from './pages/blogs/Blogs';

function App() {
  let userLoggedIn = false;
  let userFromLocalStorage = localStorage.getItem("user");
  if (userFromLocalStorage) {
    userFromLocalStorage = JSON.parse(userFromLocalStorage);
    userLoggedIn = true;
  }
  const [loggedIn, setLoggedIn] = useState(userLoggedIn);

  const getUser = () => {
    const userFromLocalStorage = localStorage.getItem("user");
    if (userFromLocalStorage) {
      return JSON.parse(userFromLocalStorage);
    }
    return null;
  };

  const setUser = (user) => {
    if (!user) {
      localStorage.removeItem("user");
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
      localStorage.setItem("user", JSON.stringify(user));
    }
  };

  const [showAuthWindow, openAuthWindow] = useState(null);

  const [inProgress, setProgress] = useState({
    showInProgress: false,
    message: null,
    messageType: null,
  });

  const showProgress = (showInProgress, message, messageType) => {
    setProgress({
      showInProgress,
      message,
      messageType,
    });
  };

  function noop() {}
  if (process.env.NODE_ENV !== 'development') {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
  }

  return (
    <AppContext.Provider
      value={{
        getUser,
        setUser,
        loggedIn,
        openAuthWindow,
        showProgress,
      }}
    >
      <div className="App">
        <ProgressBox show={inProgress}>
          <Switch>
            <Route path="/" exact>
              <Home/>
            </Route>
            <Route path="/terms-of-use" exact>
              <ToS/>
            </Route>
            <Route path="/privacy-policy" exact>
              <PrivacyPolicy/>
            </Route>
            <Route path="/editor/:resumeId/:templateId">
              <ResumeEditor print={false} />
            </Route>
            <Route path="/editor/:resumeId">
              <ResumeEditor print={false} />
            </Route>
            {process.env.REACT_APP_PRINT_SERVER === "yes" && (
              <Route path="/print/:resumeId/:authToken">
                <ResumePrint />
              </Route>
            )}
            <Route path="/blogs/:category/:article">
              <Blogs/>
            </Route>
            <Route path="/blogs/:category/">
              <Blogs/>
            </Route>
            <Route path="/blogs">
              <Blogs/>
            </Route>
          </Switch>
          {showAuthWindow && (
            <AuthenticateAccount
              register={showAuthWindow === "register" ? true : false}
            />
          )}
        </ProgressBox>
      </div>
    </AppContext.Provider>
  );
}

export default App;
