import { useContext, useState } from "react";
import EditableSection from "../primitives/EditableSection";
import InputField from "../primitives/InputField";

import ResumeContext from "../../store/resume-builder-context";

const Summary = (props) => {
  const summary = props.data;
  const context = useContext(ResumeContext);
  const resumeState = context.resumeState;
  const templateState = context.templateState;
  const [isSubSectionEditing, setSubSectionEditState] = useState(false);

  const removeHandler = () => {
  };

  const changeHandler = (id, value) => {
    switch (id) {
      case "description":
        summary.description = value;
        break;
      default:
        break;
    }
    console.log(summary);
    context.resumeStateDispatch({
      type: "updateSummary",
      payload: summary,
    });
  };

  return (
    <EditableSection
      isRemovable={false}
      highlight={true}
      onRemove={removeHandler}
      setSubSectionEditState={setSubSectionEditState}
      page={props.page}
      panel={props.panel}
      section={props.section}
      id={props.id}
      key={props.id}
    >
      <div>
        <InputField
          placeholder="Description"
          optional={false}
          fontWeight={300}
          fontSize={templateState.fontSize.normal}
          id="description"
          onChange={changeHandler}
        >
          {summary.description}
        </InputField>
      </div>
    </EditableSection>
  );
};

export default Summary;
