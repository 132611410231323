import { useContext, useReducer, useRef, useState } from "react";
import AppContext from "../../store/app-context";
import classes from "./AuthenticateAccount.module.css";
import FormButton from "../primitives/FormButton";
import FormInput from "../primitives/FormInput";
import * as api from "../../api/index.js";
import GoogleLogin from "react-google-login";

const validateName = (text) => {
  let error = "";
  if (text.length === 0) {
    error = "This is a required field";
  }
  return error;
};

const validateEmail = (text) => {
  let error = "";
  if (text.length === 0) {
    return "This is a required field";
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = re.test(text);
  if (!valid) {
    error = "Please enter a valid email";
  }
  return error;
};

const validatePassword = (text) => {
  let error = "";
  if (text.length === 0) {
    return "This is a required field";
  }
  if (text.length < 6) {
    return "Password should be minimum 6 characters";
  }
  return error;
};

const validateRegisterCode = (text) => {
  let error = "";
  if (text.length === 0) {
    return "This is a required field";
  }
  if (text.length !== 6) {
    return "Code should be 6 characters";
  }
  return error;
};

const formStateReducer = (state, action) => {
  const updatedState = { ...state };
  switch (action.type) {
    case "registerName": {
      updatedState.registerName.value = action.payload;
      const msg = validateName(updatedState.registerName.value);
      updatedState.registerName.msg = msg;
      return updatedState;
    }
    case "registerEmail": {
      updatedState.registerEmail.value = action.payload;
      const msg = validateEmail(updatedState.registerEmail.value);
      updatedState.registerEmail.msg = msg;
      return updatedState;
    }
    case "registerPassword": {
      updatedState.registerPassword.value = action.payload;
      const msg = validatePassword(updatedState.registerPassword.value);
      updatedState.registerPassword.msg = msg;
      return updatedState;
    }
    case "registerCode": {
      updatedState.registerCode.value = action.payload;
      const msg = validateRegisterCode(updatedState.registerCode.value);
      updatedState.registerCode.msg = msg;
      return updatedState;
    }
    case "signInEmail": {
      updatedState.signInEmail.value = action.payload;
      const msg = validateEmail(updatedState.signInEmail.value);
      updatedState.signInEmail.msg = msg;
      return updatedState;
    }
    case "signInPassword": {
      updatedState.signInPassword.value = action.payload;
      const msg = validatePassword(updatedState.signInPassword.value);
      updatedState.signInPassword.msg = msg;
      return updatedState;
    }
    case "forgotEmail": {
      updatedState.forgotEmail.value = action.payload;
      const msg = validateEmail(updatedState.forgotEmail.value);
      updatedState.forgotEmail.msg = msg;
      return updatedState;
    }
    case "forgotPassword": {
      updatedState.forgotPassword.value = action.payload;
      const msg = validatePassword(updatedState.forgotPassword.value);
      updatedState.forgotPassword.msg = msg;
      return updatedState;
    }
    case "forgotCode": {
      updatedState.forgotCode.value = action.payload;
      const msg = validateRegisterCode(updatedState.forgotCode.value);
      updatedState.forgotCode.msg = msg;
      return updatedState;
    }
    case "validateRegisterCode": {
      updatedState.registerName.msg = validateName(
        updatedState.registerName.value
      );
      updatedState.registerEmail.msg = validateEmail(
        updatedState.registerEmail.value
      );
      updatedState.registerPassword.msg = validatePassword(
        updatedState.registerPassword.value
      );
      return updatedState;
    }
    case "validateRegister": {
      updatedState.registerName.msg = validateName(
        updatedState.registerName.value
      );
      updatedState.registerEmail.msg = validateEmail(
        updatedState.registerEmail.value
      );
      updatedState.registerPassword.msg = validatePassword(
        updatedState.registerPassword.value
      );
      updatedState.registerCode.msg = validateRegisterCode(
        updatedState.registerCode.value
      );
      return updatedState;
    }
    case "validateSignIn": {
      updatedState.signInEmail.msg = validateEmail(
        updatedState.signInEmail.value
      );
      updatedState.signInPassword.msg = validatePassword(
        updatedState.signInPassword.value
      );
      return updatedState;
    }
    case "validateForgotPasswordCode": {
      updatedState.forgotEmail.msg = validateEmail(
        updatedState.forgotEmail.value
      );
      return updatedState;
    }
    case "validateForgotPassword": {
      updatedState.forgotEmail.msg = validateEmail(
        updatedState.forgotEmail.value
      );
      updatedState.forgotPassword.msg = validatePassword(
        updatedState.forgotPassword.value
      );
      updatedState.forgotCode.msg = validateRegisterCode(
        updatedState.forgotCode.value
      );
      return updatedState;
    }
    default: {
    }
  }
};

const initialFormState = {
  registerName: {
    value: "",
    msg: "",
  },
  registerEmail: {
    value: "",
    msg: "",
  },
  registerPassword: {
    value: "",
    msg: "",
  },
  registerCode: {
    value: "",
    msg: "",
  },
  signInEmail: {
    value: "",
    msg: "",
  },
  signInPassword: {
    value: "",
    msg: "",
  },
  forgotEmail: {
    value: "",
    msg: "",
  },
  forgotPassword: {
    value: "",
    msg: "",
  },
  forgotCode: {
    value: "",
    msg: "",
  },
};

const AuthenticateAccount = (props) => {
  const appContext = useContext(AppContext);
  const openAuthWindow = appContext.openAuthWindow;
  console.log(props.register);
  const inititalState = props.register ? "register" : "signin";
  const [windowState, setWindowState] = useState(inititalState);
  const [formState, dispatchFormState] = useReducer(
    formStateReducer,
    initialFormState
  );
  const optionClickHandler = (option) => {
    if (option === "register") {
      setWindowState("register");
    } else {
      setWindowState("signin");
    }
  };

  const registerSendCodeHandler = async () => {
    dispatchFormState({
      type: "validateRegisterCode",
    });
    if (
      formState.registerName.msg.length !== 0 ||
      formState.registerEmail.msg.length !== 0 ||
      formState.registerPassword.msg.length !== 0 ||
      formState.registerName.value.length === 0 ||
      formState.registerEmail.value.length === 0 ||
      formState.registerPassword.value.length === 0
    ) {
      return;
    }
    appContext.showProgress(true);
    try {
      const response = await api.registerSendCode(
        formState.registerName.value,
        formState.registerEmail.value
      );
      console.log(response);
      const responseOK = response && response.status === 200;
      if (responseOK) {
        appContext.showProgress(false);
        setWindowState("registerCode");
      } else {
        appContext.showProgress(false, "Failed to register", "failure");
      }
    } catch (error) {
      if (error.response.data.hasOwnProperty("message")) {
        appContext.showProgress(false, error.response.data.message, "failure");
      } else {
        appContext.showProgress(false, "Failed to register", "failure");
      }
    } finally {
    }
  };

  const registerCompleteHandler = async () => {
    if (
      formState.registerName.msg.length !== 0 ||
      formState.registerEmail.msg.length !== 0 ||
      formState.registerPassword.msg.length !== 0 ||
      formState.registerCode.msg.length !== 0 ||
      formState.registerName.value.length === 0 ||
      formState.registerEmail.value.length === 0 ||
      formState.registerPassword.value.length === 0 ||
      formState.registerCode.value.length === 0
    ) {
      return;
    }
    appContext.showProgress(true);
    try {
      const response = await api.register(
        formState.registerName.value,
        formState.registerEmail.value,
        formState.registerPassword.value,
        formState.registerCode.value
      );
      console.log(response);
      const responseOK = response && response.status === 200;
      if (responseOK) {
        window.gtag("event", "sign_up", {
          method: "password",
        });
        appContext.setUser(response.data);
        openAuthWindow(null);
        appContext.showProgress(false);
      } else {
        appContext.showProgress(false, "Failed to register", "failure");
      }
    } catch (error) {
      if (error.response.data.hasOwnProperty("message")) {
        appContext.showProgress(false, error.response.data.message, "failure");
      } else {
        appContext.showProgress(false, "Failed to register", "failure");
      }
    } finally {
    }
  };

  const signInHandler = async () => {
    if (
      formState.signInEmail.msg.length !== 0 ||
      formState.signInPassword.msg.length !== 0 ||
      formState.signInEmail.value.length === 0 ||
      formState.signInPassword.value.length === 0
    ) {
      return;
    }
    appContext.showProgress(true);
    try {
      const response = await api.signin(
        formState.signInEmail.value,
        formState.signInPassword.value
      );
      appContext.showProgress(false);
      console.log(response);
      const responseOK = response && response.status === 200;
      if (responseOK) {
        window.gtag("event", "login", {
          method: "password",
        });
        appContext.setUser(response.data);
        openAuthWindow(null);
      } else {
        appContext.showProgress(false, "Failed to sign in", "failure");
      }
    } catch (error) {
      if (error.response.data.hasOwnProperty("message")) {
        appContext.showProgress(false, error.response.data.message, "failure");
      } else {
        appContext.showProgress(false, "Failed to sign in", "failure");
      }
    } finally {
    }
  };

  const authBackdropRef = useRef();
  const authCloseHandler = (event) => {
    console.log("backdrop clicked. auth vanish");
    event.stopPropagation();
    openAuthWindow(null);
  };

  const googleSignInSuccessHandler = async (googleResponse) => {
    console.log("google sign in success");
    console.log(googleResponse);
    appContext.showProgress(true);
    try {
      const response = await api.googleSignin(
        googleResponse.profileObj.name,
        googleResponse.profileObj.email,
        googleResponse.tokenId
      );
      console.log(response);
      const responseOK = response && response.status === 200;
      if (responseOK) {
        window.gtag("event", "login", {
          method: "google",
        });
        appContext.setUser(response.data);
        openAuthWindow(null);
        appContext.showProgress(false);
      } else {
        appContext.showProgress(false, "Failed to register", "failure");
      }
    } catch (error) {
      if (error.response.data.hasOwnProperty("message")) {
        appContext.showProgress(false, error.response.data.message, "failure");
      } else {
        appContext.showProgress(false, "Failed to register", "failure");
      }
    } finally {
    }
  };

  const googleSignInFailureHandler = async (response) => {
    console.log("google sign in failure");
    console.log(response);
    appContext.showProgress(false, "Failed to Sign in", "failure");
  };

  const forgotPasswordSendCodeHandler = async () => {
    dispatchFormState({
      type: "validateForgotPasswordCode",
    });
    if (
      formState.forgotEmail.msg.length !== 0 ||
      formState.forgotEmail.value.length === 0
    ) {
      return;
    }
    appContext.showProgress(true);
    try {
      const response = await api.forgotPasswordSendCode(
        formState.forgotEmail.value
      );
      console.log(response);
      const responseOK = response && response.status === 200;
      if (responseOK) {
        appContext.showProgress(false);
        setWindowState("forgotPasswordCode");
      } else {
        appContext.showProgress(false, "Failed to change password", "failure");
      }
    } catch (error) {
      if (error.response.data.hasOwnProperty("message")) {
        appContext.showProgress(false, error.response.data.message, "failure");
      } else {
        appContext.showProgress(false, "Failed to change password", "failure");
      }
    } finally {
    }
  };

  const forgotPasswordCompleteHandler = async () => {
    if (
      formState.forgotEmail.msg.length !== 0 ||
      formState.forgotPassword.msg.length !== 0 ||
      formState.forgotCode.msg.length !== 0 ||
      formState.forgotEmail.value.length === 0 ||
      formState.forgotPassword.value.length === 0 ||
      formState.forgotCode.value.length === 0
    ) {
      return;
    }
    appContext.showProgress(true);
    try {
      const response = await api.forgotPassword(
        formState.forgotEmail.value,
        formState.forgotPassword.value,
        formState.forgotCode.value
      );
      console.log(response);
      const responseOK = response && response.status === 200;
      if (responseOK) {
        appContext.setUser(response.data);
        openAuthWindow(null);
        appContext.showProgress(false);
      } else {
        appContext.showProgress(false, "Failed to change password", "failure");
      }
    } catch (error) {
      if (error.response.data.hasOwnProperty("message")) {
        appContext.showProgress(false, error.response.data.message, "failure");
      } else {
        appContext.showProgress(false, "Failed to change password", "failure");
      }
    } finally {
    }
  };

  return (
    <div className={classes.authenticateScreen}>
      <div
        className={classes.authenticateBackdrop}
        ref={authBackdropRef}
        onClick={authCloseHandler}
      ></div>
      <div className={classes.authenticateContainer}>
        {/* <div className={classes.leftPanel}>
          <div>A new job starts with A resume. We strive to get it right for you.</div>
          <div className={classes.logo}>DASHINGRESUME</div>
        </div> */}
        <div className={classes.rightPanel}>
          {["register", "signin"].includes(windowState) && (
            <div className={classes.optionChooserContainer}>
              <div
                className={`${classes.option} ${
                  windowState === "register" ? classes.optionSelected : ""
                }`}
                onClick={() => {
                  optionClickHandler("register");
                }}
              >
                Register
              </div>
              <div
                className={`${classes.option} ${
                  windowState === "signin" ? classes.optionSelected : ""
                }`}
                onClick={() => {
                  optionClickHandler("signin");
                }}
              >
                Sign In
              </div>
            </div>
          )}
          {windowState === "register" && (
            <div className={classes.optionBodyContainer}>
              <FormInput
                type="text"
                placeholder="Your Name"
                text={formState.registerName.value}
                error={formState.registerName.msg}
                inputId="registerName"
                onChange={dispatchFormState}
              />
              <FormInput
                type="email"
                placeholder="Your Email"
                text={formState.registerEmail.value}
                error={formState.registerEmail.msg}
                inputId="registerEmail"
                onChange={dispatchFormState}
              />
              <FormInput
                type="password"
                placeholder="Password"
                text={formState.registerPassword.value}
                error={formState.registerPassword.msg}
                inputId="registerPassword"
                onChange={dispatchFormState}
              />
              <FormButton text="Register" onClick={registerSendCodeHandler} />
              <div className={classes.orText}>OR</div>
              <div className={classes.googleButtonContainer}>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Sign up with Google"
                  onSuccess={googleSignInSuccessHandler}
                  onFailure={googleSignInFailureHandler}
                  cookiePolicy={"single_host_origin"}
                  uxMode="popup"
                />
              </div>
            </div>
          )}
          {windowState === "signin" && (
            <div className={classes.optionBodyContainer}>
              <FormInput
                type="email"
                placeholder="Your Email"
                text={formState.signInEmail.value}
                error={formState.signInEmail.msg}
                inputId="signInEmail"
                onChange={dispatchFormState}
              />
              <FormInput
                type="password"
                placeholder="Password"
                text={formState.signInPassword.value}
                error={formState.signInPassword.msg}
                inputId="signInPassword"
                onChange={dispatchFormState}
              />
              <div
                className={classes.forgotPassword}
                onClick={() => {
                  setWindowState("forgotPassword");
                }}
              >
                Forgot password
              </div>
              <FormButton text="Sign In" onClick={signInHandler} />
              <div className={classes.orText}>OR</div>
              <div className={classes.googleButtonContainer}>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Sign in with Google"
                  onSuccess={googleSignInSuccessHandler}
                  onFailure={googleSignInFailureHandler}
                  cookiePolicy={"single_host_origin"}
                  uxMode="popup"
                />
              </div>
            </div>
          )}
          {windowState === "registerCode" && (
            <div className={classes.centeredDiv}>
              <div className={classes.registerCodePrompt}>
                We have sent you a 6 digit code at:
              </div>
              <div className={classes.registerCodePromptEmail}>
                {formState.registerEmail.value}
              </div>
              <div className={classes.registerCodeInputWrapper}>
                <FormInput
                  type="text"
                  placeholder="Code"
                  center={true}
                  text={formState.registerCode.value}
                  error={formState.registerCode.msg}
                  inputId="registerCode"
                  onChange={dispatchFormState}
                />
              </div>
              <FormButton
                text="Complete Registration"
                onClick={registerCompleteHandler}
              />
              <FormButton
                text="Resend Code"
                onClick={registerSendCodeHandler}
                theme="theme2"
              />
            </div>
          )}
          {windowState === "forgotPassword" && (
            <div className={classes.optionBodyContainer}>
              <FormInput
                type="email"
                placeholder="Your Email"
                text={formState.forgotEmail.value}
                error={formState.forgotEmail.msg}
                inputId="forgotEmail"
                onChange={dispatchFormState}
              />
              <FormButton
                text="Send Code"
                onClick={forgotPasswordSendCodeHandler}
              />
            </div>
          )}
          {windowState === "forgotPasswordCode" && (
            <div className={classes.centeredDiv}>
              <FormInput
                type="password"
                placeholder="New Password"
                text={formState.forgotPassword.value}
                error={formState.forgotPassword.msg}
                inputId="forgotPassword"
                onChange={dispatchFormState}
              />
              <div className={classes.registerCodePrompt}>
                We have sent you a 6 digit code at:
              </div>
              <div className={classes.registerCodePromptEmail}>
                {formState.forgotEmail.value}
              </div>
              <div className={classes.registerCodeInputWrapper}>
                <FormInput
                  type="text"
                  placeholder="Code"
                  center={true}
                  text={formState.forgotCode.value}
                  error={formState.forgotCode.msg}
                  inputId="forgotCode"
                  onChange={dispatchFormState}
                />
              </div>
              <FormButton
                text="Change Password"
                onClick={forgotPasswordCompleteHandler}
              />
              <FormButton
                text="Resend Code"
                onClick={forgotPasswordSendCodeHandler}
                theme="theme2"
              />
            </div>
          )}
          <div className={classes.disclaimer}>
            By signing up, you agree to our{" "}
            <a href="/terms-of-use" target="_blank">Terms of Use</a> and{" "}
            <a href="/privacy-policy" target="_blank">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticateAccount;
