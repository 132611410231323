import classes from './PanelWidthController.module.css';
import PanelWidthControllerBlock from './PanelWidthControllerBlock';

const PanelWidthController = (props) => {
  const blockCount = props.blockCount;

  const blockClickHandler = (blockIndex) => {
    props.onPanelWidthChange((100/blockCount) * (blockIndex + 1));
  }

  return (
    <div className={classes.container}>
      {
        Array.from(Array(blockCount).keys()).map(blockIndex => {
          return <PanelWidthControllerBlock key={blockIndex} blockIndex={blockIndex} flex={100/blockCount} onBlockClick={blockClickHandler}/>;
        })
      }
    </div>
  );
};

export default PanelWidthController;