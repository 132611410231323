import NavBar from "../components/NavBar";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Styles = styled.div`
  .tosContainer {
    background-color: #FFFFFF;
    padding-top: 50px;
    padding-bottom: 100px;
    font-family: "Roboto", sans-serif;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  p {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.5em;
  }

  h2 {
    margin-top: 50px;
  }
`;

const PrivacyPolicy = (props) => {
  return (
    <Styles>
      <NavBar />
      <div className="tosContainer">
        <Container className="">
          <h1>Privacy Policy</h1>
          <h2>What personal data does Dashingresume use?</h2>
          <p>When you sign up/register as a Dashingresume user, you provide us with your basic information, as well as any other data you want to be included in your resume, cover letter and/or online portfolio or data that might help Dashingresume to provide you assistance in your job seeking & career development. Furthermore, if you wish to use the Dashingresume premium features, we might also ask for payment details.</p>
          <p>The mandatory personal data to create an account includes your first and last name, e-mail address, and password. You are required to provide data which is necessary to create a personal account and use Dashingresume. Your refusal to provide this data determines that you cannot use Dashingresume.</p>
          <p>We recommend that you keep your account data updated at all times.</p>
          <p>Like many web/app/software applications operators, we also collect information that your browser sends whenever you visit Dashingresume. This Log Data may include information such as your computer's Internet Protocol („IP”) address, your location (city, country), browser type, browser version, and the pages of Dashingresume that you visit, the time and date of your visit, the time spent on those pages and other statistics (hereinafter Personal Data).</p>
          <h2>How do we use it?</h2>
          <p>Usage information is used primarily in aggregate for statistical purposes. It helps us understand which parts of our product are of greatest interest and also provides information used to improve our platform and develop exciting new features. This information is shared with our third-party analytics services (including Google Analytics). Those services operate either within the EU or in accordance with the EU-US Privacy Shield, ensuring the secure transfer of information.</p>
          <p>Resume content is stored for the purpose of providing you with the primary service of Dashingresume - creating, storing, displaying, and rendering your resume. This information is stored in JSON format in MongoDB Atlas database hosting.</p>
          <h2>Data retention</h2>
          <p>Usage information stored in Google Analytics is kept up to 26 months after your last visit and afterwards used only in aggregate reports. Usage information in Amplitude, Intercom, and Attribution App is kept for an indefinite period of time and used only in aggregate to analyze usage patterns.</p>
          <p>Resume content is stored for the duration of that resume being kept on our platform. If you delete your resume or delete your account, all resume content is instantaneously removed from our database.</p>
          <h2>Log information</h2>
          <p>Log data contain data about the nature of each access of our services, including originating Internet Protocol (IP) addresses and endpoint (the requested files on our platform), as well as timestamps for this activity. Web servers usually keep log files that record data each time a device accesses those servers.</p>
          <p>We will also collect error logs which may collect more system data needed to fix known platform bugs.</p>
          <p>Log data is used to analyze platform usage, improve performance and eliminate any bugs. We primarily look at this information in aggregate, but we might review individual logs when looking for the cause of a specific issues. This may happen either by our own initiative or in relation to a request you log with our customer success team.</p>
          <p>Log data stored on our platform is kept for an indefinite period of time to help us review potential recurrence of bugs and user issues, and to monitor any attempts for unauthorised access to our services.</p>
          <h2>Sensitive information</h2>
          <p>We do not knowingly collect any Sensitive Data about you. Sensitive data refers to data that includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data. We do not collect any information about criminal convictions and offences. If you include such information as part of the content of your resume, we would still not purposefully collect and store this information or disclose to anyone.</p>
          <h2>Cookies</h2>
          <p>A "cookie" is a piece of information that is stored on your computer and which records how you move your way around a website, so that when you revisit that website, it can present tailored options based on the information stored about your last visit. Cookies can also be used to analyse traffic and for advertising and marketing purposes. Cookies are used by nearly all websites and do not harm your system.</p>
          <p>Cookies can be session-based - only stored on your computer during your web session and are automatically deleted when you close your browser - or persistent - remaining even after you close your browser. Persistent cookies are stored as a file on your computer and can be read only by the website that created them when you visit that website again.</p>
          <p>We use cookies to track your use of our website. This enables us to understand how you use the site and track any patterns with regards how you are using our website. This helps us to develop and improve our platform in response to what you might need or want.</p>
          <p>For registered users, our platform uses cookies and HTML5 browser local storage to save your preferences and authenticate you. The third-party services we use might also use cookies, pixel tags and other similar technologies.</p>
          <p>Cookies are also used to pursue our legitimate interests of improving marketing performance by analyzing the effectiveness of advertising and other promotional efforts.</p>
          <p>We use cookies in Google Analytics, Amplitude and Attribution App to analyze aggregate data for the purposes outlined above. We also cookies or pixel tags for services such as Facebook, LinkedIn, or Twitter in order to create and manage marketing campaigns</p>
          <p>You can accept or decline cookies by modifying the settings in your browser. However, you will not be able to use all the interactive features of Dashingresume if cookies are disabled.</p>
          <p>Most recent web browsers allow control over the cookies saved through the browser setting.</p>
          <h2>Security</h2>
          <p>Dashingresume  shall take appropriate organizational and technical measures to protect your Information and traffic data provided to us/them or collected by us/them, and shall not retain it any longer than permitted in order to perform its Services or as required under relevant legislation. Your personal data can only be accessed by authorized employees of Dashingresume, or by Dashingresume authorized partners' employees needing to access this data to fulfill their given duties.</p>
          <p>Dashingresume will always strive to protect your Personal Data, but if you choose the option of sharing your professional documents through our sharing option (this includes sharing the link to your digital application or by sharing your documents for receiving feedback) on 3rd party software, websites/apps or services you do it with your consent and at your own risk acknowledging that Dashingresume cannot be held accountable for what happens with the personal information after your share it outside of Dashingresume.</p>
          <p>The safety and security of your information also depend on you. Where we have given you (or where you have chosen) a password or access through a unique code on your email address for access to certain parts of Dashingresume, you are responsible for keeping the password and the content in your email inbox confidential. We ask you not to share your password, the unique information that you will receive in your email account or the login link with anyone. We urge you to be careful about giving out information in public areas of Dashingresume like message/comment boards.</p>
          <p>The information you choose at your own will to share publicly will be accessible by everyone who has access to that particular software, website/app or service, therefore, we are not able to protect it. By default, all your data is private. However, if you share your application documents on social media through or without our web application, or share your application online on a unique URL, or by email, or in public places of Dashingresume, or by third-party software, websites/apps or services, we cannot guarantee the privacy of the data you have used in those documents and Dashingresume cannot be held liable for what happens to it after you have shared it. Therefore, we would suggest being careful where and with whom you are sharing your documents or other professional information created/shared with Dashingresume.</p>
          <p>We have implemented measures designed to secure your private information from accidental loss and unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Unfortunately, the transmission of information via the internet is not 100% secure. Although we do our best to protect your private information, we cannot guarantee the security of your private information transmitted to Dashingresume. Any transmission of personal information is at your risk. We are not responsible for circumvention of any privacy settings or security measures contained on Dashingresume. However, our team always strives to keep your information as secure as possible all the time.</p>
          <p>Dashingresume uses third-party service providers in order to monitor usage, ensure proper use, functioning, maintenance, and improvement of its services.</p>
          <p>This use may include some personal information as follows: IP address, cookies, etc.</p>
          <p>By using our services, users understand and agree that Dashingresume cannot be held responsible in case of any unauthorized and wrongful use by such third-party service providers (e.g. cookie high jacking).</p>
          <h2>Changes to This Privacy Policy</h2>
          <p>Occasionally Dashingresume might make changes to the Privacy Policy. When such changes are made, Dashingresume will make sure to provide you with prominent notice as appropriate under the circumstances, e.g., by displaying a prominent notice within Dashingresume or by sending you an email. In some cases, we will notify you in advance. Please, therefore, make sure you read any such notice carefully.</p>
          <p>If you have any questions or concerns regarding this policy or the Dashingresume service, please contact us at hello@dashingresume.com.</p>
        </Container>
      </div>
    </Styles>
  );
};

export default PrivacyPolicy;
