import classes from "./Page.module.css";

import React, { useContext, useEffect, useReducer, useRef, useState } from "react";

import Panel from "./Panel";
import Section from "./Section";
import Experience from "../sections/Experience";
import PersonalDetails from "../sections/PersonalDetails";
import Education from "../sections/Education";
import Project from "../sections/Project";
import ResumeContext from "../../store/resume-builder-context";
import Skill from "../sections/Skill";
import Interest from "../sections/Interest";
import Achievement from "../sections/Achievement";
import Certificate from "../sections/Certificate";
import Summary from "../sections/Summary";
import Language from "../sections/Language";

const overflowReducer = (state, action) => {
  const updatedState = [...state];
  updatedState[action.idx] = action.overflow;
  return updatedState;
};

const Page = (props) => {
  const pageIdx = props.idx;
  const context = useContext(ResumeContext);
  // const pageState = context.resumeState.pageState.pages[pageIdx];
  const resumeState = context.resumeState;
  const templateState = context.templateState;
  const panelCount = templateState.panels.length;
  const initialOverflowState = new Array(panelCount).fill(false);
  const [overflowState, overflowDispatch] = useReducer(
    overflowReducer,
    initialOverflowState
  );
  const pageRef = useRef();
  const [pageWidth, setPageWidth] = useState(792);

  const headerClass = templateState?.pageHeaderDivider ? `${classes.header} ${classes.headerDivider}` : `${classes.header}`; 

  const createHeader = (section) => {
    switch (section) {
      case "personal_details":
        const personalDetails = resumeState.data.personalDetails;
        if (!personalDetails) {
          return;
        }
        let theme = templateState?.sections?.personal_details?.theme;
        theme = theme ? theme: 'theme1';
        return (
          <PersonalDetails
            data={personalDetails}
            key={section}
            page={pageIdx}
            section={section}
            id={personalDetails.id}
            theme={theme}
          />
        );
      default:
        return;
    }
  };

  const createSection = (panelIndex, section) => {
    const ids = []
    for (let item=0; item < resumeState.data[section].items.length; item++) {
      if (resumeState.data[section].items[item].page === pageIdx) {
        ids.push(resumeState.data[section].items[item].id);
      }
    }
    // console.log(`page: ${pageIdx} panel: ${panelIndex} ids: ${ids}`);
    if (ids.length === 0) {
      return;
    }
    switch (section) {
      case "experiences":
        return (
          <Section
            key={section}
            title={context.resumeState.data.experiences.title}
            onAdd={() => context.resumeStateDispatch({ type: "addExperience" })}
            onTitleChange={(id, value) => {
              console.log(value);
              context.resumeStateDispatch({
                type: "updateExperienceTitle",
                payload: value,
              });
            }}
          >
            {context.resumeState.data.experiences.items.map((experience) => {
              if (ids.includes(experience.id)) {
                return (
                  <Experience
                    data={experience}
                    key={experience.id}
                    page={pageIdx}
                    panel={panelIndex}
                    section={section}
                    id={experience.id}
                  />
                );
              }
            })}
          </Section>
        );
      case "educations":
        return (
          <Section
            key={section}
            title={context.resumeState.data.educations.title}
            onAdd={() => context.resumeStateDispatch({ type: "addEducation" })}
            onTitleChange={(id, value) => {
              console.log(value);
              context.resumeStateDispatch({
                type: "updateEducationTitle",
                payload: value,
              });
            }}
          >
            {context.resumeState.data.educations.items.map((education) => {
              if (ids.includes(education.id)) {
                return (
                  <Education
                    data={education}
                    key={education.id}
                    page={pageIdx}
                    panel={panelIndex}
                    section={section}
                    id={education.id}
                  />
                );
              }
            })}
          </Section>
        );
      case "skills":
        let theme = templateState?.sections?.skills?.theme;
        theme = theme ? theme: 'filled';
        return (
          <Section
            key={section}
            title={context.resumeState.data.skills.title}
            onAdd={() => context.resumeStateDispatch({ type: "addSkill" })}
            onTitleChange={(id, value) => {
              console.log(value);
              context.resumeStateDispatch({
                type: "updateSkillsTitle",
                payload: value,
              });
            }}
          >
            {context.resumeState.data.skills.items.map((skill) => {
              if (ids.includes(skill.id)) {
                return (
                  <Skill
                    data={skill}
                    key={skill.id}
                    page={pageIdx}
                    panel={panelIndex}
                    section={section}
                    id={skill.id}
                    theme={theme}
                  />
                );
              }
            })}
          </Section>
        );
      case "projects":
        return (
          <Section
            key={section}
            title={context.resumeState.data.projects.title}
            onAdd={() => context.resumeStateDispatch({ type: "addProject" })}
            onTitleChange={(id, value) => {
              console.log(value);
              context.resumeStateDispatch({
                type: "updateProjectsTitle",
                payload: value,
              });
            }}
          >
            {context.resumeState.data.projects.items.map((project) => {
              if (ids.includes(project.id)) {
                return (
                  <Project
                    data={project}
                    key={project.id}
                    page={pageIdx}
                    panel={panelIndex}
                    section={section}
                    id={project.id}
                  />
                );
              }
            })}
          </Section>
        );
      case "interests":
        return (
          <Section
            key={section}
            title={context.resumeState.data.interests.title}
            onAdd={() => context.resumeStateDispatch({ type: "addInterest" })}
            onTitleChange={(id, value) => {
              console.log(value);
              context.resumeStateDispatch({
                type: "updateInterestsTitle",
                payload: value,
              });
            }}
          >
            {context.resumeState.data.interests.items.map((interest) => {
              if (ids.includes(interest.id)) {
                return (
                  <Interest
                    data={interest}
                    key={interest.id}
                    page={pageIdx}
                    panel={panelIndex}
                    section={section}
                    id={interest.id}
                  />
                );
              }
            })}
          </Section>
        );
      case "languages":
        return (
          <Section
            key={section}
            title={context.resumeState.data.languages.title}
            onAdd={() => context.resumeStateDispatch({ type: "addLanguage" })}
            onTitleChange={(id, value) => {
              console.log(value);
              context.resumeStateDispatch({
                type: "updateLanguagesTitle",
                payload: value,
              });
            }}
          >
            {context.resumeState.data.languages.items.map((language) => {
              if (ids.includes(language.id)) {
                return (
                  <Language
                    data={language}
                    key={language.id}
                    page={pageIdx}
                    panel={panelIndex}
                    section={section}
                    id={language.id}
                  />
                );
              }
            })}
          </Section>
        );
      case "achievements":
        return (
          <Section
            key={section}
            title={context.resumeState.data.achievements.title}
            onAdd={() => context.resumeStateDispatch({ type: "addAchievement" })}
            onTitleChange={(id, value) => {
              console.log(value);
              context.resumeStateDispatch({
                type: "updateAchievementsTitle",
                payload: value,
              });
            }}
          >
            {context.resumeState.data.achievements.items.map((achievement) => {
              if (ids.includes(achievement.id)) {
                return (
                  <Achievement
                    data={achievement}
                    key={achievement.id}
                    page={pageIdx}
                    panel={panelIndex}
                    section={section}
                    id={achievement.id}
                  />
                );
              }
            })}
          </Section>
        );
      case "certificates":
        return (
          <Section
            key={section}
            title={context.resumeState.data.certificates.title}
            onAdd={() => context.resumeStateDispatch({ type: "addCertificate" })}
            onTitleChange={(id, value) => {
              console.log(value);
              context.resumeStateDispatch({
                type: "updateCertificatesTitle",
                payload: value,
              });
            }}
          >
            {context.resumeState.data.certificates.items.map((certificate) => {
              if (ids.includes(certificate.id)) {
                return (
                  <Certificate
                    data={certificate}
                    key={certificate.id}
                    page={pageIdx}
                    panel={panelIndex}
                    section={section}
                    id={certificate.id}
                  />
                );
              }
            })}
          </Section>
        );
      case "summary":
        return (
          <Section
            key={section}
            title={context.resumeState.data.summary.title}
            onTitleChange={(id, value) => {
              console.log(value);
              context.resumeStateDispatch({
                type: "updateSummaryTitle",
                payload: value,
              });
            }}
          >
            {context.resumeState.data.summary.items.map((s) => {
              if (ids.includes(s.id)) {
                return (
                  <Summary
                    data={s}
                    key={s.id}
                    page={pageIdx}
                    panel={panelIndex}
                    section={section}
                    id={s.id}
                  />
                );
              }
            })}
          </Section>
        );
    }
  };

  useEffect(() => {
    setPageWidth(pageRef.current.offsetWidth);
  }, []);

  return (
    <div className={`page ${classes.page}`} ref={pageRef}>
      <div className={classes.marginWrapper}>
        {pageIdx === 0 && templateState.header.length > 0 && (
          <div className={headerClass}>
            {templateState.header &&
              templateState.header.map((section) => {
                return createHeader(section);
              })}
          </div>
        )}
        <div className={classes.body}>
          {templateState.panels.map((panel, panelIndex) => {
            return (
              <Panel
                idx={panelIndex}
                key={panelIndex}
                highlight={overflowState[panelIndex]}
                flex={panel.flex}
                rightPad={panel.rightPad}
                overflowDispatch={overflowDispatch}
              >
                {panel.items.map((section) => {
                  // const ids = pageState.panels[panelIndex][section];
                  return createSection(panelIndex, section);
                })}
              </Panel>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Page;
