import classes from "./EditBar.module.css";
import SideDrawer from "./SideDrawer";

import ResumeEditorContext from "../../store/resume-editor-context";
import AppContext from "../../store/app-context";
import { useContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const EditBar = (props) => {
  const editorContext = useContext(ResumeEditorContext);
  const appContext = useContext(AppContext);
  const editBarWholeRef = useRef();

  const root = document.getElementById("root");
  const slideDrawer = document.getElementById("slideDrawer");;

  const itemClickHandler = (item) => {
    console.log("itemClickHandler");
    editorContext.openDrawer(item);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        editBarWholeRef.current && slideDrawer &&
        !editBarWholeRef.current.contains(event.target) && 
        !slideDrawer.contains(event.target)
      ) {
        editorContext.openDrawer(null);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [editBarWholeRef, slideDrawer, editorContext]);

  return (
    <div ref={editBarWholeRef} className={classes.editWholeContainer}>
      <div className={classes.editBarContainer}>
        { appContext.loggedIn &&
          <div
          className={classes.editItem}
          onClick={() => itemClickHandler("myresumes")}
          >
            My Resumes
          </div>
        }
        <div
          className={classes.editItem}
          onClick={() => itemClickHandler("templates")}
        >
          Templates
        </div>
        <div
          className={classes.editItem}
          onClick={() => itemClickHandler("layout")}
        >
          Layout
        </div>
        <div
          className={classes.editItem}
          onClick={() => itemClickHandler("font")}
        >
          Font
        </div>
        <div
          className={classes.editItem}
          onClick={() => itemClickHandler("colour")}
        >
          Colour
        </div>
      </div>
      <div>{ReactDOM.createPortal(<SideDrawer show={true}/>, root)}</div>
    </div>
  );
};

export default EditBar;
