import classes from "./Education.module.css";

import { useContext, useEffect, useState } from "react";
import EditableSection from "../primitives/EditableSection";
import InputField from "../primitives/InputField";
import ListInputField from "../primitives/ListInputField";
import ListInputFieldItem from "../primitives/ListInputFieldItem";

import ResumeContext from "../../store/resume-builder-context";

const Education = (props) => {
  const education = props.data;
  const context = useContext(ResumeContext);
  const resumeState = context.resumeState;
  const templateState = context.templateState;
  const [isSubSectionEditing, setSubSectionEditState] = useState(false);

  const removeHandler = () => {
    context.resumeStateDispatch({
      type: "removeEducation",
      payload: education.id,
    });
  };

  const addPointHandler = () => {
    context.resumeStateDispatch({
      type: "addEducationPoint",
      payload: education.id,
    });
  };

  const changeHandler = (id, value) => {
    switch (id) {
      case "course":
        education.course = value;
        break;
      case "institute":
        education.institute = value;
        break;
      case "period":
        education.period = value;
        break;
      case "description":
        education.description = value;
        break;
      default:
        for (var i in education.points) {
          if (education.points[i].id === id) {
            education.points[i].text = value;
          }
        }
    }
    console.log(education);
    context.resumeStateDispatch({
      type: "updateEducation",
      payload: education,
    });
  };

  useEffect(() => {
    let needCleaning = false;
    for (var i in education.points) {
      if (education.points[i].text === "") {
        needCleaning = true;
      }
    }
    if (!isSubSectionEditing && needCleaning) {
      context.resumeStateDispatch({
        type: "cleanEducation",
        payload: education.id,
      });
    }
  }, [isSubSectionEditing]);

  return (
    <EditableSection
      isRemovable={true}
      highlight={true}
      onRemove={removeHandler}
      setSubSectionEditState={setSubSectionEditState}
      page={props.page}
      panel={props.panel}
      section={props.section}
      id={props.id}
      key={props.id}
    >
      <div className={classes.experience}>
        <InputField
          placeholder="Course / Program"
          optional={false}
          capitalize={false}
          fontWeight={700}
          fontSize={templateState.fontSize.medium}
          id="course"
          onChange={changeHandler}
        >
          {education.course}
        </InputField>

        <InputField
          placeholder="Institution"
          optional={false}
          fontWeight={400}
          fontSize={templateState.fontSize.medium}
          id="institute"
          onChange={changeHandler}
        >
          {education.institute}
        </InputField>

        <InputField
          placeholder="period"
          optional={false}
          fontWeight={400}
          fontSize={templateState.fontSize.small}
          id="period"
          onChange={changeHandler}
        >
          {education.period}
        </InputField>

        <InputField
          placeholder="Description (optional)"
          optional={true}
          fontWeight={300}
          fontSize={templateState.fontSize.small}
          id="description"
          onChange={changeHandler}
        >
          {education.description}
        </InputField>

        <ListInputField onAdd={addPointHandler}>
          {education.points.map((point) => {
            return (
              <ListInputFieldItem key={point.id}>
                <InputField
                  id={point.id}
                  onChange={changeHandler}
                  placeholder="Achievements"
                  fontSize={templateState.fontSize.normal}
                >
                  {point.text}
                </InputField>
              </ListInputFieldItem>
            );
          })}
        </ListInputField>
      </div>
    </EditableSection>
  );
};

export default Education;
