import { useState, useEffect, useRef, useContext } from "react";
import EditableSectionContext from "../../store/editable-section-context";
import PanelContext from "../../store/panel-context";
import ResumeContext from "../../store/resume-builder-context";
import SectionContext from "../../store/section-context";

import classes from "./EditableSection.module.css";
import RemoveButton from "./RemoveButton";

const EditableSection = (props) => {
  const isRemovable = props.isRemovable;
  const setSubSectionEditState = props.setSubSectionEditState;
  const [isEditing, setIsEditing] = useState(false);
  const esRef = useRef();
  const resumeContext = useContext(ResumeContext);
  const panelContext = useContext(PanelContext);
  const sectionContext = useContext(SectionContext);
  const [isVisible, setIsVisible] = useState(true);

  const page = props.page;
  const section = props.section;
  const panel = props.panel;
  const id = props.id;

  const setEditMode = () => {
    console.log("ES editmode");
    setIsEditing(true);
    if (setSubSectionEditState) {
      setSubSectionEditState(true);
    }
  };

  const setViewMode = () => {
    console.log("ES viewmode");
    setIsEditing(false);
    if (setSubSectionEditState) {
      setSubSectionEditState(false);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (esRef.current && !esRef.current.contains(event.target)) {
        setIsEditing(false);
        if (setSubSectionEditState) {
          setSubSectionEditState(false);
        }
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [esRef, setSubSectionEditState]);

  const inivisibleCheck = () => {
    if (isEditing) {
      return;
    }
    const subSectionToPanelOffset =
      sectionContext.sectionToPanelOffset + esRef.current.offsetTop + esRef.current.offsetHeight;
    const panelHeight = panelContext.height;
    // console.log(subSectionToPanelOffset, panelHeight);
    if (subSectionToPanelOffset && panelHeight) {
      if (subSectionToPanelOffset > panelHeight) {
        console.log("invisible", page, panel, section , id, subSectionToPanelOffset, panelHeight);
        setIsVisible(false);
        resumeContext.resumeStateDispatch({
          type: "pageStateInvisible",
          page,
          panel,
          section,
          id,
          templateState: resumeContext.templateState
        });
      } else {
        // console.log("visible", page, panel, section , id, subSectionToPanelOffset, panelHeight);
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    inivisibleCheck();
  }, [resumeContext]);

  const highlightClass = isEditing && props.highlight ? classes.highlight : classes.noHighlight;
  // const highlightClass = !isVisible ? classes.highlight : classes.noHighlight;
  let typeClass = classes.regularSection;
  if (props.type === "list") {
    typeClass = classes.listSection;
  }

  return (
    <EditableSectionContext.Provider
      value={{
        isEditing,
        setEditMode,
        setViewMode,
      }}
    >
      <div
        ref={esRef}
        className={`${classes.editSection} ${highlightClass} ${typeClass}`}
        onClick={setEditMode}
      >
        {props.children}
        <span className={classes.removeButtonContainer}>
          {isRemovable && isEditing && <RemoveButton onRemove={props.onRemove} />}
        </span>
      </div>
    </EditableSectionContext.Provider>
  );
};

export default EditableSection;
